import React, { useState, useEffect } from 'react';
import '../Home/home.scss';
import { useNavigate } from 'react-router-dom';

import './bhadarviPoonam.scss';
// import notice from "../../Assets/Images/Bhadarvi_poonam/notice.jpg";
import LazyLoader from '../../Components/lazyLoader';
import ParkingArea from '../../Assets/Images/51_shaktipith/parking-area.svg';
import yagna from '../../Assets/Images/E_store/yagna.svg';
import ladooIcon from '../../Assets/Images/E_store/ladooIcon.svg';
import calendar from '../../Assets/Images/51_shaktipith/calendar.svg';
import MasalaRed from '../../Assets/Images/51_shaktipith/MasalaRed.svg';
import festival from '../../Assets/Images/51_shaktipith/festival.svg';
import pdffile from '../../Assets/Images/Bhadarvi_poonam/pdf.svg';
import Registration from '../../Assets/Images/Bhadarvi_poonam/registrationform.svg';
import Previewfile from '../../Assets/Images/Bhadarvi_poonam/file.svg';
import Calendar from '../../Assets/Images/Bhadarvi_poonam/calendar.svg';
import downarrow from '../../Assets/Images/Bhadarvi_poonam/down.svg';
import Arasuri from '../../Components/arasuri';
import Footer from '../../Components/footer';
// import Select from "react-select";
import { useFormik } from 'formik';
import SanghService from '../../Api_config/Bhadarvi_poonam/sangh';
// import { useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import SevaCampService from '../../Api_config/Bhadarvi_poonam/sevaCamp';
import { SevaCampSchema } from '../../_Constants/validations';
// import { formatDate } from "../Donation/Converter/date";
// import SignatureService from "../../Api_config/Home_page/signature";
import { Helmet } from 'react-helmet-async';
import { Padding } from '@mui/icons-material';

// import UnderConstruction from "../Under_Construction/underconstruction";

// function closePopup() {
//   const modal = document.getElementById("myModal");
//   if (modal) {
//     modal.style.display = "none";
//   }
// }

const BhadarviPoonam = (props) => {
  const { data } = props;
  // const location = useLocation();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [5000]);
  });

  // year dyanmic
  const currentYear = new Date().getFullYear();
  // document.getElementById("currentYear").textContent = currentYear;

  //-----page-close-UnderConstruction-logic-p-----
  // if (true) {
  //   return <UnderConstruction />;
  // }
  //-----page-close-404-error-logic-p-----

  return (
    <>
      {/* helmet  */}
      <Helmet>
        <title>Ambaji Bhadarvi Poonam 2024</title>
        <meta
          name="description"
          content="Discover the divine celebration of Ambaji Bharavi Poonam 2024 at Ambaji Temple. Join thousands of devotees for an enriching spiritual experience filled with rituals, prayers, and cultural festivities. You can View Temple Schedule, Access to Crucial Information, Seva Camp Registration, Sangh Registration at Ambaji Temple Website"
        />
        <meta
          name="keywords"
          content="Ambaji Bharavi Poonam 2024, Ambaji Temple, Bharavi Poonam festival, Ambaji pilgrimage, spiritual events, religious festivals India, Ambaji celebrations, Hindu festivals, Ambaji Poonam schedule, visit Ambaji Temple, divine rituals, cultural festivities, sacred events Gujarat, Ambaji event details, Seva Camp Registration, Sangh Registration"
        />
        <meta
          name="author"
          content="Ambaji Temple, Banaskatha Gujarat | BinTech Services"
        />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {/* ------------  */}
      {!isLoading ? (
        <>
          {/* <div id="container">
            <div id="myModal">
              <div id="popUpForm">
                <div id="popContainer">
                  <div className="modal-content">
                    <button id="close" onClick={closePopup}>
                      X
                    </button>
                    <img
                      src={notice}
                      alt="Ambaji Temple Banaskatha Gujarat"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <section className="mainbanner">
            <div className="bannerslider">
              <div className="banner-item">
                <div className="bannerimg bhadarvi-bg">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="main-heading-bhadarvi">
                          <div className="bhadarvi-text">
                            <h1>
                              ભાદરવી પૂનમ<span> {currentYear}</span>
                            </h1>
                          </div>
                          <a className="arrow_scroll_down" href="#bhadarvi">
                            <span id="bhadarvi"> </span>
                            <img src={downarrow} />
                            <p>નીચે સ્ક્રોલ કરો</p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="Advertise-business">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h3 style={{ margin: '0px 0px 15px' }}>
                    ભાદરવી પૂનમ <span>ની ઓનલાઇન સુવિધાઓ</span>
                  </h3>
                </div>
              </div>

              <div className="service-list">
                <div className="row">
                  {/* PARKING  */}

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <a href="#popup-open">
                      <div className="bhadarvi-box-color">
                        <div className="service-icon">
                          <img
                            src={Calendar}
                            alt="Ambaji Temple Banaskatha Gujarat"
                          ></img>
                        </div>
                        <h5>ભાદરવી પૂનમ {currentYear} (સમય અનુસૂચિ)</h5>
                      </div>
                    </a>
                  </div>

                  <div id="popup-open" className="overlay-box">
                    <div className="popup-box">
                      <h2>View Schedule</h2>
                      <a className="close" href="#">
                        &times;
                      </a>
                      <hr />
                      <div className="content-show">
                        <div className="table-responsive-1">
                          <table>
                            <thead>
                              <tr>
                                <th colSpan="3">
                                  ભાદરવી પૂનમ {currentYear} (દર્શન-સમય)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <strong>સવારે ૬.૦૦ થી ૧૧.૩૦</strong>
                                </td>
                                <td>
                                  <strong>બપોરે ૧૨.૩૦ થી ૫.૦૦</strong>
                                </td>
                                <td>
                                  <strong>સાંજે ૭.૦૦ થી રાત્રિના ૧૨.૦૦</strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table>
                            <thead>
                              <tr>
                                <th colSpan="2">
                                  ભાદરવી પૂનમ {currentYear} (આરતી-સમય)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <strong>સવારે ૬.૦૦ થી ૬.૩૦</strong>
                                </td>
                                <td>
                                  <strong>સાંજે ૭.૦૦ થી ૭.૩૦</strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br />
                        <div className="table-responsive-1">
                          <table>
                            <thead>
                              <tr>
                                <th>તિથિ</th>
                                <th>વાર</th>
                                <th>તારીખ</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>ભાદરવા સુદ-નોમ</td>
                                <td>ગુરુવાર</td>
                                <td>૧૨-૦૯-૨૦૨૪</td>
                              </tr>
                              <tr>
                                <td>ભાદરવા સુદ-દશમ</td>
                                <td>શુક્રવાર</td>
                                <td>૧૩-૦૯-૨૦૨૪</td>
                              </tr>
                              <tr>
                                <td>ભાદરવા સુદ-અગિયારસ</td>
                                <td>શનિવાર</td>
                                <td>૧૪-૦૯-૨૦૨૪</td>
                              </tr>
                              <tr>
                                <td>ભાદરવા સુદ-બારસ</td>
                                <td>રવિવાર</td>
                                <td>૧૫-૦૯-૨૦૨૪</td>
                              </tr>
                              <tr>
                                <td>ભાદરવા સુદ-તેરસ</td>
                                <td>સોમવાર</td>
                                <td>૧૬-૦૯-૨૦૨૪</td>
                              </tr>
                              <tr>
                                <td>ભાદરવા સુદ-ચૌદસ</td>
                                <td>મંગળવાર</td>
                                <td>૧૭-૦૯-૨૦૨૪</td>
                              </tr>
                              <tr>
                                <td>ભાદરવા સુદ-પૂનમ</td>
                                <td>બુધવાર</td>
                                <td>૧૮-૦૯-૨૦૨૪</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <a href="/seva-terms-conditions" target="_blank">
                      <div className="bhadarvi-box-color">
                        <div className="service-icon">
                          <img
                            src={Registration}
                            alt="Ambaji Temple Banaskatha Gujarat"
                          ></img>
                        </div>
                        <h5>સેવા કેમ્પ નોંધણી</h5>
                      </div>
                    </a>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <a href="/sang-terms-conditions" target="_blank">
                      <div className="bhadarvi-box-color">
                        <div className="service-icon">
                          <img
                            src={Registration}
                            alt="Ambaji Temple Banaskatha Gujarat"
                          ></img>
                        </div>
                        <h5>પદયાત્રા સંઘ નોંધણી</h5>
                      </div>
                    </a>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <a href="/bhadarvi-poonam-receipt-download" target="_blank">
                      <div className="bhadarvi-box-color">
                        <div className="service-icon">
                          <img
                            src={pdffile}
                            alt="Ambaji Temple Banaskatha Gujarat"
                          ></img>
                        </div>
                        <h5>સેવા - કેમ્પ અને સંઘ નોંધણી ની રસીદ</h5>
                      </div>
                    </a>
                  </div>

                  {/* <div className="col-lg-4 col-md-6 col-sm-12">
                    <a href="/ambaji-sthanik-vehicle-pass" target="_blank">
                      <div className="bhadarvi-box-color">
                        <div className="service-icon">
                          <img
                            src={Registration}
                            alt="Ambaji Temple Banaskatha Gujarat"
                          ></img>
                        </div>
                        <h5>અંબાજી સ્થાનિક વાહન પાસ નોંધણી</h5>
                      </div>
                    </a>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <a
                      href="/search-ambaji-sthanik-vehicle-pass"
                      target="_blank"
                    >
                      <div className="bhadarvi-box-color">
                        <div className="service-icon">
                          <img
                            src={Previewfile}
                            alt="Ambaji Temple Banaskatha Gujarat"
                          ></img>
                        </div>
                        <h5>અંબાજી સ્થાનિક વાહન પાસ - સર્ચ</h5>
                      </div>
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <section className="service-section">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <h3 style={{ margin: '0px 0px 15px' }}>
                    ભક્તો <span>માટે ની સુવિધા</span>
                  </h3>
                  <div className="service-list">
                    <div className="row">
                      {/* PARKING  */}
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <a
                          href="https://maps.app.goo.gl/5VWAXnpviyqsgPwt6"
                          target="_blank"
                        >
                          <div className="servicebox">
                            <div className="service-icon">
                              <img
                                src={ParkingArea}
                                alt="Ambaji Temple Banaskatha Gujarat"
                              ></img>
                            </div>
                            <h5>
                              {/* Parking */}
                              પાર્કિંગ
                            </h5>
                          </div>
                        </a>
                      </div>
                      {/* ------------- */}

                      {/* Temple  */}
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="servicebox">
                          <div className="service-icon">
                            <img
                              src={calendar}
                              alt="Ambaji Temple Banaskatha Gujarat"
                            ></img>
                          </div>
                          <h5>મંદિર</h5>
                        </div>
                      </div>
                      {/* ------------- */}

                      {/* Ambika Bhojnalay  */}
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="servicebox">
                          <div className="service-icon">
                            <img
                              src={MasalaRed}
                              alt="Ambaji Temple Banaskatha Gujarat"
                            ></img>
                          </div>
                          <h5>અંબિકા ભોજનાલય</h5>
                        </div>
                      </div>
                      {/* ------------- */}

                      {/* Donation Counter  */}
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="servicebox">
                          <div className="service-icon">
                            <img
                              src={yagna}
                              alt="Ambaji Temple Banaskatha Gujarat"
                            ></img>
                          </div>
                          <h5>ડોનેશન કાઉન્ટર</h5>
                        </div>
                      </div>
                      {/* ------------- */}

                      {/* Prasad Counter  */}
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="servicebox">
                          <div className="service-icon">
                            <img
                              src={ladooIcon}
                              alt="Ambaji Temple Banaskatha Gujarat"
                            ></img>
                          </div>
                          <h5>પ્રસાદ કાઉન્ટર</h5>
                        </div>
                      </div>
                      {/* ------------- */}

                      {/* Cultural Program  */}
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="servicebox">
                          <div className="service-icon">
                            <img
                              src={festival}
                              alt="Ambaji Temple Banaskatha Gujarat"
                            ></img>
                          </div>
                          <h5>સાંસ્કૃતિક કાર્યક્રમ</h5>
                        </div>
                      </div>
                      {/* ------------- */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Arasuri />
          <Footer />
        </>
      ) : (
        <LazyLoader />
      )}
    </>
  );
};

export default BhadarviPoonam;
