import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Footer from "../../Components/footer";
import "../Home/home.scss";
import "./liveStream.scss";
import Arasuri from "../../Components/arasuri";
import LazyLoader from "../../Components/lazyLoader";

const LiveStream = () => {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [5000]);
  });
  return (
    <>
      <Helmet>
        <title>Ambaji Temple | Banaskantha, Gujarat</title>
        <meta
          name="description"
          content="Managed by Shri Ambaji Mata Devasthan Trust, Ambaji Temple is one of the 51 Shakti
Peethas where the heart of Goddess Sati fell. Experience live darshan, view temple and aarti
timing, donate online, contribute to the Suvarna Shikhar Gold, book Pavdi Puja, Dhaja, and
Yagna through our website. Join us in worship and devotion from anywhere in the world."
        />
        <meta
          name="keywords"
          content="Ambaji Temple, Ambaji Temple queries, Ambaji Temple information, Temple contact details,
Banaskantha temple contact, Religious assistance India, Ambaji Temple, Ambaji Mataji,
Ambaji Gujarat, Ambaji Darshan, Ambaji Temple Timing, Ambaji Temple Gabbar "
        />
        <meta
          name="author"
          content="Ambaji Temple and Gabbar Banaskantha Gujarat | BinTech Services Ahmedabad"
        />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {!isLoading ? (
        <div className="main-wrapper">
          <section className="LiveStream-page">
            <div className="container-fluid">
              <div className="row d-flex justify-content-center">
                <div className="col-xl-12">
                  <embed
                    width="100%"
                    height="700"
                    src="https://www.youtube.com/embed/P2-zdM-p9o4?si=aZ226N5M0IDU7cBi"
                    title="YouTube video player"
                    frameBorder="0"
                  ></embed>
                </div>
              </div>
            </div>
          </section>

          <Arasuri />
          <Footer />
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
};

export default LiveStream;
