import * as Yup from 'yup';
import { regex } from './regex';

// ----------------- Contact Us Schema -----------------------
export const ContactUsSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .matches(
      regex.onlyAlphabet,
      'Only alphabets and special characters are allowed'
    ),
  email_id: Yup.string()
    .required('Email is required')
    .matches(regex.emailPattern, 'Invalid Email Id'),
  contact_number: Yup.string()
    .required('Primary Contact Number is required')
    .min(10, 'Contact Number must be at least 10 characters')
    .max(10, 'Contact Number must be at most 10 characters')
});

// ----------------- Money Donation Schema -----------------------

export const MoneyDonationSchema = Yup.object().shape({
  amount: Yup.string()
    .required('Amount is required')
    .matches(regex.onlyNumbers, 'Only Postive Numbers are allowed')
});
export const goldDonationSchema = Yup.object().shape({
  amount: Yup.string()
    .required('Amount is required')
    .matches(regex.onlyNumbers, 'Only Postive Numbers are allowed')
});

// ----------------- Login Schema -----------------------

export const LoginSchema = Yup.object().shape({
  primary_contact_number: Yup.string()
    .required('Contact Number is required')
    .matches(regex.onlyNumbers, 'Only Postive Numbers are allowed'),

  password: Yup.string().required('Password is required')
});
// Seva Camp
export const SevaCampSchema = Yup.object().shape({
  // name: Yup.string()
  //   .matches(/^[a-zA-Z\s]+$/, "Only English letters are allowed")
  //   .required("Name is required"),
  name: Yup.string()
    .required('Name is required')
    .test(
      'is-not-gujarati',
      'Gujarati text is not allowed',
      (value) => !/[\u0A80-\u0AFF]/.test(value)
    ),

  start_date: Yup.string().required('Start Date is required'),
  end_date: Yup.string().required('End Date is required'),
  trust_name: Yup.string()
    .required(' Name of the Trust conducting the Seva Camp is required')
    .test(
      'is-not-gujarati',
      'Gujarati text is not allowed',
      (value) => !/[\u0A80-\u0AFF]/.test(value)
    ),
  representative_primary_contact_number: Yup.string().required(
    'Organiser’s Phone Number is required'
  ),
  address_line_1: Yup.string()
    .required(' Seva Camp Address Line 1 is required')
    .test(
      'is-not-gujarati',
      'Gujarati text is not allowed',
      (value) => !/[\u0A80-\u0AFF]/.test(value)
    ),
  village: Yup.string()
    .required('Vilage is required')
    .test(
      'is-not-gujarati',
      'Gujarati text is not allowed',
      (value) => !/[\u0A80-\u0AFF]/.test(value)
    ),
  taluka: Yup.string()
    .required('Taluka is required')
    .test(
      'is-not-gujarati',
      'Gujarati text is not allowed',
      (value) => !/[\u0A80-\u0AFF]/.test(value)
    ),
  district: Yup.string()
    .required('District is required')
    .test(
      'is-not-gujarati',
      'Gujarati text is not allowed',
      (value) => !/[\u0A80-\u0AFF]/.test(value)
    ),
  representative_name: Yup.string()
    .required('Name of Head Person of Seva Camp is required')
    .test(
      'is-not-gujarati',
      'Gujarati text is not allowed',
      (value) => !/[\u0A80-\u0AFF]/.test(value)
    ),
  identity_proof_type: Yup.string().required('ID Proof Type is required'),
  // identity_proof: Yup.mixed().required("ID Proof is required"),
  seva_camp_type_id: Yup.string().required('Camp Type is required'),
  vehicle1_category_id: Yup.string().required('Vehicle Category is required'),
  arrival_route_id: Yup.string().required(
    'Road of Seva Camp Establishment is required'
  ),
  vehicle1_number: Yup.string().required('Vehicle Number  is required'),
  no_of_vehicles: Yup.string().required('Number of Vehicle is required')
});
// SwayamSevak
export const SwayamSevakSchema = Yup.object().shape({
  swayma_sevak_name: Yup.string().required('Name is required'),

  date: Yup.string().required('Date is required'),
  email_id: Yup.string().required('Email Id is required'),

  primary_contact_number: Yup.string().required('Phone Number is required'),
  address_line_1: Yup.string()
    .required(' Seva Camp Address Line 1 is required')
    .test(
      'is-not-gujarati',
      'Gujarati text is not allowed',
      (value) => !/[\u0A80-\u0AFF]/.test(value)
    ),
  zip_code: Yup.string().required('Pincode is requierd'),
  village: Yup.string()
    .required('Vilage is required')
    .test(
      'is-not-gujarati',
      'Gujarati text is not allowed',
      (value) => !/[\u0A80-\u0AFF]/.test(value)
    ),
  taluka: Yup.string()
    .required('Taluka is required')
    .test(
      'is-not-gujarati',
      'Gujarati text is not allowed',
      (value) => !/[\u0A80-\u0AFF]/.test(value)
    ),
  district: Yup.string()
    .required('District is required')
    .test(
      'is-not-gujarati',
      'Gujarati text is not allowed',
      (value) => !/[\u0A80-\u0AFF]/.test(value)
    ),
  no_of_days: Yup.string().required(
    ' How many days in a year do you want to do service work is required'
  ),
  gender: Yup.string().required('Gender is required'),
  age: Yup.string().required('Age is required')
});
// Sangh
export const SanghSchema = Yup.object().shape({
  organization_name: Yup.string()
    .required('Organization Name is required')
    .test(
      'is-not-gujarati',
      'Gujarati text is not allowed',
      (value) => !/[\u0A80-\u0AFF]/.test(value)
    ),
  organizer_name: Yup.string()
    .required('Organizer Name is required')
    .test(
      'is-not-gujarati',
      'Gujarati text is not allowed',
      (value) => !/[\u0A80-\u0AFF]/.test(value)
    ),
  primary_contact_number: Yup.string().required('Contact Number is required'),
  email_id: Yup.string().required('Email Id is required'),
  address_line_1: Yup.string()
    .required('Address Line 1 is required')
    .test(
      'is-not-gujarati',
      'Gujarati text is not allowed',
      (value) => !/[\u0A80-\u0AFF]/.test(value)
    ),
  village: Yup.string()
    .required('Village is required')
    .test(
      'is-not-gujarati',
      'Gujarati text is not allowed',
      (value) => !/[\u0A80-\u0AFF]/.test(value)
    ),
  taluka: Yup.string()
    .required('Taluka is required')
    .test(
      'is-not-gujarati',
      'Gujarati text is not allowed',
      (value) => !/[\u0A80-\u0AFF]/.test(value)
    ),
  arrival_date: Yup.string().required('Arrivale Date  is required'),
  no_of_people: Yup.string().required('Number of people  is required'),
  arrival_route_id: Yup.string().required('Route Type is required'),
  vehicle1_category_id: Yup.string().required('Vehicle Category is required'),
  vehicle1_number: Yup.string().required('Vehicle Number  is required'),
  // vehicle3_number: Yup.string().required("Vehicle Number  is required"),

  // vehicle2_category_id: Yup.string().required(
  //   "Vehicle Category 2  is required"
  // ),
  // vehicle3_number: Yup.string().required("Vehicle Number 3 is required"),

  // vehicle2_number: Yup.string().required("Vehicle Number 2 is required"),
  no_of_vehicles: Yup.string().required('Number of Vehicle is required')
});
// Vehicle Pass
// Sangh
export const EmpVehiclePass = Yup.object().shape({
  first_name: Yup.string()
    .required('First Name is required')
    .test(
      'is-not-gujarati',
      'Gujarati text is not allowed',
      (value) => !/[\u0A80-\u0AFF]/.test(value)
    ),
  last_name: Yup.string()
    .required('Last Name is required')
    .test(
      'is-not-gujarati',
      'Gujarati text is not allowed',
      (value) => !/[\u0A80-\u0AFF]/.test(value)
    ),
  primary_contact_number: Yup.string().required(
    'Primary Contact Number is required'
  ),
  email_id: Yup.string().required('Email Id is required'),
  pass_type: Yup.string().required('Pass Issue For is required'),
  // start_date: Yup.string().required("Start Date  is required"),
  // end_date: Yup.string().required("End Date is required"),
  // arrival_route_id: Yup.string().required(
  //   " Road of Seva Camp Establishment  is required"
  // ),
  vehicle_category: Yup.string().required('Vehicle Category is required'),
  vehicle_number: Yup.string().required('Vehicle Number  is required'),
  office_address: Yup.string().required('Office Address  is required'),
  designation: Yup.string().required('Designation  is required'),
  // employee_doc: Yup.mixed().required("Employee Identity Proof is required"),
  // vehicle_doc: Yup.mixed().required("Vehicle Document is required"),
  // order_doc: Yup.mixed().required("Bhadarvi Poonam Order is required"),

  // vehicle2_category_id: Yup.string().required(
  //   "Vehicle Category 2  is required"
  // ),
  // vehicle3_number: Yup.string().required("Vehicle Number 3 is required"),

  // vehicle2_number: Yup.string().required("Vehicle Number 2 is required"),
  department: Yup.string().required('Department is required')
});
// ----------------- SignUp Schema -----------------------

export const SignUpSchema = Yup.object().shape({
  first_name: Yup.string()
    .required('First Name is required')
    .matches(regex.onlyAlphabet, 'Only Alphabets are allowed'),

  last_name: Yup.string()
    .required('Last Name is required')
    .matches(regex.onlyAlphabet, 'Only Alphabets are allowed'),

  primary_contact_number: Yup.string()
    .required('Contact Number is required')
    .matches(regex.onlyNumbers, 'Only Postive Numbers are allowed'),

  password: Yup.string()
    .required('Password is required')
    .matches(regex.passwordPattern, 'password is invalid'),

  confirm_password: Yup.string()
    .required('Confirm Password is required')
    .matches(regex.passwordPattern, 'confirm password is invalid'),

  email_id: Yup.string()
    .required('Email is required')
    .matches(regex.emailPattern, 'Invalid Email Id')
});

// ----------------- Forgot password Schema -----------------------

export const ForgotPasswordSchema = Yup.object().shape({
  primary_contact_number: Yup.string()
    .required('Contact Number is required')
    .matches(regex.onlyNumbers, 'Only Postive Numbers are allowed'),

  new_password: Yup.string()
    .required('New Password is required.')
    .matches(regex.passwordPattern, 'New Password is invalid'),

  confirm_password: Yup.string()
    .required('Confirm Password is required')
    .matches(regex.passwordPattern, 'confirm password is invalid')
});

// ----------------- Yagna Booking Schema -----------------------
export const YagnaBookingSchema = Yup.object().shape({
  // yagna_date: Yup.string().required('Date is required'),
  yagna_temple_id: Yup.string().required('Temple is required'),
  //   yagna_category_id: Yup.string().required('Yagna Category is required'),
  first_name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
  email_id: Yup.string()
    .matches(regex.emailPattern, 'Invalid Email Id')
    .required('Email Id is required'),
  primary_contact_number: Yup.string()
    .required('Mobile Number is required')
    .min(
      9,
      'Mobile Number must be at least 9 digits, at most 12 digits and only contains number'
    )
    .max(
      12,
      'Mobile Number must be at least 9 digits, at most 12 digits and only contains number'
    )
    .matches(
      regex.secondaryPhonePattern,
      'Mobile Number must be at least 9 digits, at most 12 digits and only contains positive number'
    ),
  primary_contact_code_id: Yup.string().required('Country Code is required'),
  address: Yup.string().required('Address is required')
});
// Address
export const AddressSchema = Yup.object().shape({
  // name: Yup.string()
  //   .matches(/^[a-zA-Z\s]+$/, "Only English letters are allowed")
  //   .required("Name is required"),
  first_name: Yup.string().required('First Name is required'),

  last_name: Yup.string().required('Last Name is required'),
  address_type: Yup.string().required('Address Type is required'),

  primary_contact_number: Yup.string().required('Contact Number is required'),
  address_line_1: Yup.string()
    .required('Address Line 1 is required')
    .test(
      'is-not-gujarati',
      'Gujarati text is not allowed',
      (value) => !/[\u0A80-\u0AFF]/.test(value)
    ),

  country_id: Yup.string().required('Country is required'),
  province_id: Yup.string().required('Province is required'),
  city_id: Yup.string().required('City is required'),
  zipcode_id: Yup.string().required('ZipCode  is required')
});
