import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import Select from 'react-select';
import './sang.scss';
import Arasuri from '../../../Components/arasuri';
import Footer from '../../../Components/footer';
import SanghService from '../../../Api_config/Bhadarvi_poonam/sangh';
import { useNavigate } from 'react-router-dom';
import UtilsService from '../../../Api_config/Home_page/utils';
import { ToastContainer, toast } from 'react-toastify';
import { SanghSchema } from '../../../_Constants/validations';
import { routes } from '../../../_Constants/routes';
import { Helmet } from 'react-helmet-async';
import { useHeading } from '../../Bhadarvi_poonam/header'; // Import the custom hook

const Sang = (props) => {
  const [backendErrors, setBackendErrors] = useState([]);
  const [loading, setLoading] = useState(true);
  const { data } = props;
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isShowToster, setShowToster] = useState(false);
  const [amount, setAmount] = useState('');
  const [showDetails, setShowDetails] = useState(true);
  const [vehicleCount, setVehicleCount] = useState(0);
  const [vehicleCategoryList, setVehicleCategoryList] = useState([]);
  const [sangList, setSangList] = useState([]);
  const [isHideIdentity, setIsHideIdentity] = useState(false);
  const [file, setFile] = useState();
  const [fileError, setFileError] = useState(false);
  const [filePreview, setFilePreview] = useState();
  const [accepted, setAccepted] = useState(false);
  const [isNumberRegistered, setNumberRegistered] = useState(false);
  const [userData, setUserData] = useState();
  const [cityList, setCityList] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [zipcodeList, setZipcodeList] = useState([]);
  const [phoneCodeList, setPhoneCodeList] = useState([]);
  const [isNumberEditable, setNumberEditable] = useState(true);
  const [otp, setOTP] = useState();
  const [seconds, setSeconds] = useState(30);
  const [isOTPSend, setOTPSend] = useState(false);
  const [isResend, setResend] = useState(false);
  const [isShowForm, setShowForm] = useState(false);
  const [error, setError] = useState('');
  const [verifiedPhoneNumber, setVerifiedPhoneNumber] = useState('');
  const [vehicleError1, setVehicleError1] = useState('');
  const [vehicleError2, setVehicleError2] = useState('');
  const [vehicleError3, setVehicleError3] = useState('');
  const [vehicleError4, setVehicleError4] = useState('');
  const [isOTPVerified, setIsOTPVerified] = useState(false);
  const vehicleNumberRegex = /^[A-Z]{2}\d{2}[A-Z]{2}\d{4}$/; // Adjust the regex as needed
  const vehicleNumberPattern1 = /^[A-Z]{1,2}\d{2}[A-Z]{1,2}\d{4}$/;
  const vehicleNumberPattern2 = /^[A-Z]{1,2}\d{2}[A-Z]{1,2}\d{4}$/;
  const vehicleNumberPattern3 = /^[A-Z]{1,2}\d{2}[A-Z]{1,2}\d{4}$/;
  const vehicleNumberPattern4 = /^[A-Z]{1,2}\d{2}[A-Z]{1,2}\d{4}$/;
  const heading = useHeading(); // Call the custom hook

  useState(false);
  useEffect(() => {
    const isAccepted = localStorage.getItem('accepted');
    if (!JSON.parse(isAccepted)) {
      window.location.href = 'sang-terms-conditions';
    }
    setTimeout(() => {
      SanghService.getAllVehicleCategory()
        .then((res) => {
          let vehicleCategoryList = res.data.data.map(function (
            vehicleCategory
          ) {
            return { value: vehicleCategory.id, label: vehicleCategory.name };
          });
          setVehicleCategoryList(vehicleCategoryList);
        })
        .catch((e) => {
          // navigate(routes?.error500);
        });

      SanghService.getArivalroute()
        .then((res) => {
          let sangList = res.data.data.map(function (arrivalroute) {
            return { value: arrivalroute.id, label: arrivalroute.name };
          });
          // console.log(campCategoryList);
          setSangList(sangList);
        })
        .catch((e) => {
          // navigate(routes?.error500);
          // console.log(e);
        });
    }, 1000);
  }, [setVehicleCount]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  /**
   * Resend OTP
   * @param primary_contact_number
   */
  const resendOTP = () => {
    SanghService.sendOTP(formik?.values?.primary_contact_number)
      .then((res) => {
        if (res.data.success) {
          setOTPSend(true);
          setSeconds(30);
        }
      })
      .catch((error) => {
        // navigate(routes?.error500);
        toast.error(error.response?.data?.message, { autoClose: 1500 });
      });
  };
  const validateGujaratiText = (value) => {
    const gujaratiPattern = /[\u0A80-\u0AFF]/; // Pattern to detect Gujarati characters
    if (gujaratiPattern.test(value)) {
      return 'Gujarati text is not allowed';
    }
    return '';
  };
  /**
   * Verify OTP
   * @param primary_contact_number
   */
  const verifyOTP = () => {
    if (otp?.length === 6) {
      formik.setFieldValue('first_name', '');
      formik.setFieldValue('last_name', '');
      formik.setFieldValue('email_id', '');
      formik.setFieldValue('address_line_1', '');
      formik.setFieldValue('address_line_2', '');
      formik.setFieldValue('city_id', '');
      formik.setFieldValue('country_id', '');
      formik.setFieldValue('province_id', '');
      formik.setFieldValue('zipcode', '');
      formik.setFieldValue('pan_number', '');
      // formik?.resetForm()

      SanghService.verifyOTP(formik?.values?.primary_contact_number, otp)
        .then((res) => {
          if (res.data.success) {
            if (userData) {
              // setOpenModal(true);
            }
            setShowForm(true);
            setIsOTPVerified(true);
            // setOTPVerified(true);
          }
        })
        .catch((error) => {
          // navigate(routes?.error500);
          toast.error(error.response?.data?.message, { autoClose: 1500 });
        });
    }
  };
  // number registered or not
  const checkForContactNumber = (event) => {
    if (formik?.values?.primary_contact_number) {
      SanghService.getBySanghKeyword(
        formik?.values?.primary_contact_number
      ).then((res) => {
        if (res.data.success) {
          if (res.data.data.length !== 0) {
            setError('This contact number is already taken.');
            toast.error('This contact number is already taken.', {
              autoClose: 1500
            });
          } else {
            setNumberRegistered(true);
            setNumberEditable(false);
            setUserData(res.data.data[0]);
            setVerifiedPhoneNumber(formik?.values.primary_contact_number);

            SanghService.sendOTP(formik?.values?.primary_contact_number)
              .then((res) => {
                if (res.data.success) {
                  setOTPSend(true);
                  setSeconds(30);
                }
              })
              .catch((error) => {
                // navigate(routes?.error500);
                toast.error(error.response?.data?.message, {
                  autoClose: 1500
                });
              });
          }
        }
      });
    }
  };
  const submitData = (data) => {
    SanghService.create(data).then((res) => {
      // console.log(res);

      if (res.data.success) {
        setSuccessMessage(res.data.message); // Set the success message
        setFileError(false);
        setShowToster(true);
        window.location.reload(true);
      } else {
        setFileError(true);
        setSuccessMessage(null); // Clear any previous success message
      }
    });
    if (!file) {
      setFileError(true); // Set file error if file is not selected
    }
    if (!accepted) {
      handleNextButtonClick(); // Show error message if declaration is not accepted
    }
    UtilsService.getAllCountry()
      .then((res) => {
        let countryList = res.data.data.map(function (country) {
          return { value: country.id, label: country.name };
        });
        // console.log(countryList);
        // setCountryList(countryList);
        setPhoneCodeList(res.data.data);
      })
      .catch((e) => {
        // navigate(routes?.error500);
        // console.log(e);
      });
  };
  const validateVehicleNumber = (value) => {
    if (!vehicleNumberRegex.test(value)) {
      return 'Invalid vehicle number format';
    }
    if (
      value === formik.values.vehicle1_number ||
      value === formik.values.vehicle2_number ||
      value === formik.values.vehicle3_number
    ) {
      return 'Vehicle number must be unique';
    }
    return null;
  };

  const validateVehicleNumberOp1 = (value) => {
    if (!vehicleNumberPattern1.test(value)) {
      return 'Vehicle number is invalid';
    }
    return '';
  };
  const validateVehicleNumberOp2 = (value) => {
    if (!vehicleNumberPattern2.test(value)) {
      return 'Vehicle number is invalid';
    }
    return '';
  };
  const validateVehicleNumberOp3 = (value) => {
    if (!vehicleNumberPattern3.test(value)) {
      return 'Vehicle number is invalid';
    }
    return '';
  };
  const validateVehicleNumberOp4 = (value) => {
    if (!vehicleNumberPattern4.test(value)) {
      return 'Vehicle number is invalid';
    }
    return '';
  };

  const formik = useFormik({
    initialValues: {
      organization_name: '',
      organizer_name: '',
      primary_contact_number: '',
      secondary_contact_number: '',
      email_id: '',
      address_line_1: '',
      address_line_2: '',
      stay_place: '',
      village: data?.village ?? '',
      taluka: data?.taluka ?? '',
      district: data?.district ?? '',
      arrival_date: '',
      departure_date: '',
      no_of_people: '',
      no_of_vehicles: '',
      vehicle1_category_id: data?.vehicle1_category_id ?? '',
      vehicle2_category_id: data?.vehicle2_category_id ?? '',
      vehicle3_category_id: data?.vehicle3_category_id ?? '',
      vehicle4_category_id: data?.vehicle4_category_id ?? '',
      vehicle1_number: data?.vehicle1_number ?? '',
      vehicle2_number: data?.vehicle2_number ?? '',
      vehicle3_number: data?.vehicle2_number ?? '',
      vehicle4_number: data?.identity_proof ?? '',
      arrival_route_id: data?.arrival_route_id ?? '',
      primary_contact_code_id: '1',
      whatsapp_code_id: '1'
    },
    validationSchema: SanghSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      if (accepted) {
        const formData = new FormData();
        formData.append('organization_name', values.organization_name);
        formData.append('status_id', '1');

        formData.append('organizer_name', values.organizer_name);
        formData.append(
          'primary_contact_number',
          values.primary_contact_number
        );
        formData.append(
          'secondary_contact_number',
          values.secondary_contact_number
        );
        formData.append('address_line_1', values.address_line_1);
        formData.append('address_line_2', values.address_line_2);
        formData.append('stay_place', values.stay_place);
        formData.append('village', values.village);
        formData.append('taluka', values.taluka);
        formData.append('district', values.district);
        formData.append('arrival_date', values.arrival_date);
        formData.append('departure_date', values.departure_date);

        formData.append('no_of_people', values.no_of_people);
        formData.append('no_of_vehicles', values.no_of_vehicles);
        formData.append('vehicle1_category_id', values.vehicle1_category_id);
        formData.append('vehicle2_category_id', values.vehicle2_category_id);
        formData.append('vehicle3_category_id', values.vehicle3_category_id);
        formData.append('vehicle4_category_id', values.vehicle4_category_id);
        formData.append('vehicle1_number', values.vehicle1_number);
        formData.append('vehicle2_number', values.vehicle2_number);
        formData.append('vehicle3_number', values.vehicle3_number);
        formData.append('vehicle4_number', values.vehicle4_number);

        formData.append('arrival_route_id', values.arrival_route_id);
        formData.append('email_id', values.email_id);
        formData.append('contact_code_id', '1');

        SanghService.create(formData)
          .then((res) => {
            // console.log("in create api ");
            if (res.data.success) {
              toast.success(res.data.message, { autoClose: 1000 });
              setSuccessMessage(res.data.message);
              setFileError(false);
              const fetchedRegistrationNumber =
                res.data.data.registration_number;
              setRegistrationNumber(fetchedRegistrationNumber);
              navigate(`/sangh-booking-receipt/${res?.data?.data?.id}`, {
                // state: {
                //   mobileNumber: values.representative_primary_contact_number,
                //   vehicleNumber1: values.vehicle1_number,
                //   vehicleNumber2: values.vehicle2_number,
                //   registration_number: fetchedRegistrationNumber,
                // },
              });
            } else {
              setSuccessMessage(res.data.message);
              setFileError(true);
            }
          })
          .catch((error) => {
            let errorMessage = 'An error occurred. Please try again later.';
            if (error.response && error.response.data) {
              const responseData = error.response.data;
              if (responseData.error) {
                const validationErrors = responseData.error;
                errorMessage = Object.values(validationErrors)
                  .map((err) => err.message)
                  .join(', ');
              } else if (responseData.message) {
                errorMessage = responseData.message;
              }
            }
            setBackendErrors(errorMessage);
            toast.error(errorMessage, { autoClose: 1500 });
            // navigate(routes?.error500);
          });

        // .catch((error) => {
        //        navigate(routes?.error500);
        //   let errorMessage = "An error occurred. Please try again later.";
        //   if (error.response && error.response.data) {
        //     const responseData = error.response.data;
        //     if (responseData.error && responseData.error.errors) {
        //       const validationErrors = responseData.error.errors;
        //       if (
        //         Array.isArray(validationErrors) &&
        //         validationErrors.length > 0
        //       ) {
        //         errorMessage = validationErrors[0].message;
        //       }
        //     } else if (responseData.message) {
        //       errorMessage = responseData.message;
        //     }
        //   }
        //   setBackendErrors(errorMessage);
        // });
        setTimeout(() => {
          setSubmitting(false);
        }, 1000);
      } else {
        alert('You must accept declaration.');
        if (!file) {
          setFileError(true);
        }
        if (!accepted) {
          // handleNextButtonClick();
        }
      }
    }
  });

  if (!accepted) {
    formik.handleBlur('accepted');
  }

  const handleCheckboxChange = (event) => {
    setAccepted(event.target.checked);
  };

  const handleNextButtonClick = () => {
    if (accepted) {
      window.location.href = 'sangh-booking-receipt/:id';
    } else {
      alert('You must accept declaration.');
    }
  };

  const { handleChange, handleSubmit } = formik;

  return (
    <>
      {/* helmet  */}
      <Helmet>
        <title>Sangh Registration | Ambaji Bhadarvi Poonam 2024</title>
        <meta
          name="description"
          content="Register Your Pad Yatri Sangh for Ambaji Bhadarvi Poonam Fair 2024"
        />
        <meta
          name="keywords"
          content="Ambaji Bharavi Poonam 2024, Ambaji Temple, Bharavi Poonam festival, Ambaji pilgrimage, spiritual events, religious festivals India, Ambaji celebrations, Hindu festivals, Ambaji Poonam schedule, visit Ambaji Temple, divine rituals, cultural festivities, sacred events Gujarat, Ambaji event details, Seva Camp Registration, Sangh Registration"
        />
        <meta
          name="author"
          content="Ambaji Temple, Banaskatha Gujarat | BinTech Services"
        />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {/* helmet  */}
      <div className="sang-wrapper">
        <section className="header-banner Sangh-bg">
          <div className="container">{heading}</div>
        </section>
        <section className="sang-camp">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h3 style={{ color: '#ae0015' }}>
                  નોંધ : સંઘ ની વિગતો અંગ્રેજી માં ભરવા વિનંતી.
                </h3>
              </div>
            </div>
            <div className="sang-camp-box">
              {!isOTPVerified && (
                <div className="row">
                  <div className="form-group">
                    <div className="row" style={{ rowGap: '10px' }}>
                      <div className="col-xl-7 col-lg-7 col-md-10 col-sm-12">
                        <div
                          className="row align-items-end"
                          style={{ rowGap: '10px' }}
                        >
                          {/* <div className="col-xl-6 col-lg-6 col-md-7">
                            <label>
                              Organiser's Phone Number /<br />
                              આયોજકનો સંપર્ક નંબર
                            </label>
                            <input
                           type="text"
                          inputMode="numeric"
                              placeholder="Contact Number"
                              className="form-control"
                              name="primary_contact_number"
                              value={formik?.values.primary_contact_number}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d{0,10}$/.test(value)) {
                                  handleChange(e);
                                }
                              }}
                              disabled={!isNumberEditable}
                              required
                              maxLength="10"
                              pattern="\d{10}"
                            />
                           
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-5">
                            {isNumberEditable ? (
                              <button
                                type="button"
                                className="whitebtn"
                                onClick={() => checkForContactNumber()}
                              >
                                Verify Number
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="whitebtn"
                                onClick={() => [
                                  setNumberEditable(true),
                                  setNumberRegistered(false),
                                ]}
                              >
                                Edit Number
                              </button>
                            )}
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* {isNumberRegistered && !isNumberEditable && !isShowForm && (
                    <div className="form-group">
                      <div
                        className="row align-items-end"
                        style={{ rowGap: "10px" }}
                      >
                        <div className="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-7">
                          <label htmlFor="otp">OTP</label>
                          <input
                                                   inputMode="numeric"
                            minLength="6"
                            maxLength="6"
                            pattern="[0-9]*" 
                            name="otp"
                            id="otp"
                            className="form-control"
                            placeholder="_ _ _ _ _ _"
                            value={otp}
                            onChange={(event) => {
                              const inputValue = event.target.value.replace(
                                /\D/g,
                                ""
                              ); 
                              setOTP(inputValue.slice(0, 6)); 
                              setResend(false); 
                            }}
                          />
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
                          <button
                            type="button"
                            className="orangebtn"
                            onClick={() => verifyOTP()}
                            disabled={otp?.length !== 6}
                          >
                            Verify OTP
                          </button>

                          {isOTPSend && seconds == "00" && (
                            <button
                              type="button"
                              className="whitebtn ms-3"
                              onClick={() => [
                                resendOTP(),
                                setOTP(""),
                                setResend(true),
                              ]}
                            >
                              Resend OTP
                            </button>
                          )}
                         
                        </div>
                        {isOTPSend && seconds > 0 ? (
                          <p>
                            Resend OTP in
                            {seconds < 10 ? `0${seconds}` : ` ${seconds}`} sec
                          </p>
                        ) : (
                          <p></p>
                        )}
                        {!isResend && (otp?.length > 6 || otp?.length < 6) && (
                          <p className="red-text">
                            OTP must be a 6 digits number
                          </p>
                        )}
                      </div>
                    </div>
                  )} */}
                </div>
              )}
              {/* {isShowForm && !isNumberEditable ? ( */}
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Organization Name / સંસ્થાનુ - મંડળનુ નામ
                            <span style={{ color: '#ae0015' }}>*</span>
                          </label>
                          <input
                            type="text"
                            // placeholder="Organization Name"
                            // className="form-control"
                            className={`form-control ${
                              formik.touched.organization_name &&
                              formik.errors.organization_name
                                ? 'is-invalid'
                                : ''
                            }`}
                            name="organization_name"
                            value={formik?.values.organization_name}
                            onChange={(e) => {
                              formik.handleChange(e);
                              formik.setFieldTouched(
                                'organization_name',
                                true,
                                true
                              ); // Ensure field is marked as touched
                            }}
                            onBlur={(e) => {
                              formik.handleBlur(e);
                              formik.setFieldTouched(
                                'organization_name',
                                true,
                                true
                              ); // Ensure field is marked as touched
                            }}
                          />
                          {formik.touched.organization_name &&
                            formik.errors.organization_name && (
                              <div style={{ color: '#ae0015' }}>
                                {formik.errors.organization_name}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Organizer Name / આયોજકનું નામ
                            <span style={{ color: '#ae0015' }}>*</span>
                          </label>
                          <input
                            type="text"
                            // placeholder="Organizer Name"
                            // className="form-control"
                            className={`form-control ${
                              formik.touched.organizer_name &&
                              formik.errors.organizer_name
                                ? 'is-invalid'
                                : ''
                            }`}
                            name="organizer_name"
                            value={formik?.values.organizer_name}
                            onChange={(e) => {
                              formik.handleChange(e);
                              formik.setFieldTouched(
                                'organizer_name',
                                true,
                                true
                              ); // Ensure field is marked as touched
                            }}
                            onBlur={(e) => {
                              formik.handleBlur(e);
                              formik.setFieldTouched(
                                'organizer_name',
                                true,
                                true
                              ); // Ensure field is marked as touched
                            }}
                          />
                          {formik.touched.organizer_name &&
                            formik.errors.organizer_name && (
                              <div style={{ color: '#ae0015' }}>
                                {formik.errors.organizer_name}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            Organiser’s Phone Number / આયોજકનો સંપર્ક નંબર
                            <span style={{ color: '#ae0015' }}>*</span>
                          </label>
                          <input
                            type="text"
                            inputMode="numeric"
                            // placeholder="9876598765"
                            // className="form-control"
                            className={`form-control ${
                              formik.touched.primary_contact_number &&
                              formik.errors.primary_contact_number
                                ? 'is-invalid'
                                : ''
                            }`}
                            // disabled
                            name="primary_contact_number"
                            value={formik?.values.primary_contact_number}
                            onChange={(e) => {
                              const value = e.target.value;
                              // Allow only digits and limit length to 10
                              if (/^\d{0,10}$/.test(value)) {
                                handleChange(e);
                              }
                            }}
                            onBlur={formik.handleBlur}
                            maxLength={10}
                          />
                          {formik.touched.primary_contact_number &&
                            formik.errors.primary_contact_number && (
                              <div style={{ color: '#ae0015' }}>
                                {formik.errors.primary_contact_number}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            Secondary Contact Number / વૈકલ્પિક સંપર્ક નંબર
                          </label>
                          <input
                            type="text"
                            inputMode="numeric"
                            // placeholder="9876598765"
                            // className="form-control"
                            className={`form-control ${
                              formik.touched.secondary_contact_number &&
                              formik.errors.secondary_contact_number
                                ? 'is-invalid'
                                : ''
                            }`}
                            name="secondary_contact_number"
                            value={formik?.values.secondary_contact_number}
                            onChange={(e) => {
                              const value = e.target.value;
                              // Allow only digits and limit length to 10
                              if (/^\d{0,10}$/.test(value)) {
                                handleChange(e);
                              }
                            }}
                            onBlur={formik.handleBlur}
                            maxLength={10}
                          />
                          {formik.touched.secondary_contact_number &&
                            formik.errors.secondary_contact_number && (
                              <div style={{ color: '#ae0015' }}>
                                {formik.errors.secondary_contact_number}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            Organiser’s E-mail / ઈ-મેલ આઈડી
                            <span style={{ color: '#ae0015' }}>*</span>
                          </label>
                          <input
                            type="email"
                            // placeholder="Ex: info@ambajitemple.in"
                            // className="form-control"
                            className={`form-control ${
                              formik.touched.email_id && formik.errors.email_id
                                ? 'is-invalid'
                                : ''
                            }`}
                            name="email_id"
                            value={formik?.values.email_id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.email_id &&
                            formik.errors.email_id && (
                              <div style={{ color: '#ae0015' }}>
                                {formik.errors.email_id}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            Address Line 1 / આયોજકનું સરનામું 1
                            <span style={{ color: '#ae0015' }}>*</span>
                          </label>
                          <input
                            type="text"
                            // placeholder="Address Line 1"
                            className={`form-control ${
                              formik.touched.address_line_1 &&
                              formik.errors.address_line_1
                                ? 'is-invalid'
                                : ''
                            }`}
                            name="address_line_1"
                            value={formik?.values.address_line_1}
                            onChange={(e) => {
                              formik.handleChange(e);
                              formik.setFieldTouched(
                                'address_line_1',
                                true,
                                true
                              ); // Ensure field is marked as touched
                            }}
                            onBlur={(e) => {
                              formik.handleBlur(e);
                              formik.setFieldTouched(
                                'address_line_1',
                                true,
                                true
                              ); // Ensure field is marked as touched
                            }}
                          />
                          {formik.touched.address_line_1 &&
                            formik.errors.address_line_1 && (
                              <div style={{ color: '#ae0015' }}>
                                {formik.errors.address_line_1}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Address Line 2 / આયોજકનું સરનામું 2</label>
                          <input
                            type="text"
                            // placeholder="Address Line 2"
                            className={`form-control ${
                              formik.touched.address_line_2 &&
                              formik.errors.address_line_2
                                ? 'is-invalid'
                                : ''
                            }`}
                            name="address_line_2"
                            value={formik?.values.address_line_2}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.address_line_2 &&
                            formik.errors.address_line_2 && (
                              <div style={{ color: 'red' }}>
                                {formik.errors.address_line_2}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            Place of Stay in Ambaji / અંબાજીમાં રહેવાનું સ્થળ
                          </label>
                          <input
                            type="text"
                            // placeholder="Address Line 2"
                            className={`form-control ${
                              formik.touched.stay_place &&
                              formik.errors.stay_place
                                ? 'is-invalid'
                                : ''
                            }`}
                            name="stay_place"
                            value={formik?.values.stay_place}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.stay_place &&
                            formik.errors.stay_place && (
                              <div style={{ color: 'red' }}>
                                {formik.errors.stay_place}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            Village / ગામ
                            <span style={{ color: '#ae0015' }}>*</span>
                          </label>
                          <input
                            type="text"
                            // placeholder="Village"
                            className={`form-control ${
                              formik.touched.village && formik.errors.village
                                ? 'is-invalid'
                                : ''
                            }`}
                            name="village"
                            value={formik?.values.village}
                            onChange={(e) => {
                              formik.handleChange(e);
                              formik.setFieldTouched('village', true, true); // Ensure field is marked as touched
                            }}
                            onBlur={(e) => {
                              formik.handleBlur(e);
                              formik.setFieldTouched('village', true, true); // Ensure field is marked as touched
                            }}
                          />
                          {formik.touched.village && formik.errors.village && (
                            <div style={{ color: '#ae0015' }}>
                              {formik.errors.village}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            Taluka / તાલુકો
                            <span style={{ color: '#ae0015' }}>*</span>
                          </label>
                          <input
                            type="text"
                            // placeholder="Taluka"
                            className={`form-control ${
                              formik.touched.taluka && formik.errors.taluka
                                ? 'is-invalid'
                                : ''
                            }`}
                            name="taluka"
                            value={formik?.values.taluka}
                            onChange={(e) => {
                              formik.handleChange(e);
                              formik.setFieldTouched('taluka', true, true); // Ensure field is marked as touched
                            }}
                            onBlur={(e) => {
                              formik.handleBlur(e);
                              formik.setFieldTouched('taluka', true, true); // Ensure field is marked as touched
                            }}
                          />
                          {formik.touched.taluka && formik.errors.taluka && (
                            <div style={{ color: '#ae0015' }}>
                              {formik.errors.taluka}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label>District / જીલ્લો</label>
                          <input
                            type="text"
                            // placeholder="District"
                            className={`form-control ${
                              formik.touched.district && formik.errors.district
                                ? 'is-invalid'
                                : ''
                            }`}
                            name="district"
                            value={formik?.values.district}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.district &&
                            formik.errors.district && (
                              <div style={{ color: 'red' }}>
                                {formik.errors.district}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            Arrival Date / અંબાજી ખાતે પહોચવાની સંભવિત તારીખ
                            <span style={{ color: '#ae0015' }}>*</span>
                          </label>
                          <input
                            type="date"
                            // placeholder="Arrival Date"
                            className={`form-control ${
                              formik.touched.arrival_date &&
                              formik.errors.arrival_date
                                ? 'is-invalid'
                                : ''
                            }`}
                            name="arrival_date"
                            value={formik?.values.arrival_date}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            max="2024-09-18"
                            min="2024-09-01"
                          />
                          {formik.touched.arrival_date &&
                            formik.errors.arrival_date && (
                              <div
                                className="invalid-feedback"
                                style={{ color: '#ae0015' }}
                              >
                                {formik.errors.arrival_date}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            Departure Date / અંબાજીથી પરત ફરવાની તારીખ
                          </label>
                          <input
                            type="date"
                            // placeholder="Arrival Date"
                            className={`form-control ${
                              formik.touched.departure_date &&
                              formik.errors.departure_date
                                ? 'is-invalid'
                                : ''
                            }`}
                            name="departure_date"
                            value={formik?.values.departure_date}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            max="2024-09-18"
                            min="2024-09-01"
                          />
                          {formik.touched.departure_date &&
                            formik.errors.departure_date && (
                              <div
                                className="invalid-feedback"
                                style={{ color: '#ae0015' }}
                              >
                                {formik.errors.departure_date}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            Number of People / આવનાર યાત્રિકોની સંખ્યા
                            <span style={{ color: '#ae0015' }}>*</span>
                          </label>
                          <input
                            type="text"
                            inputMode="numeric"
                            // placeholder="Number of People"
                            className={`form-control ${
                              formik.touched.no_of_people &&
                              formik.errors.no_of_people
                                ? 'is-invalid'
                                : ''
                            }`}
                            name="no_of_people"
                            value={formik?.values.no_of_people}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.no_of_people &&
                            formik.errors.no_of_people && (
                              <div style={{ color: '#ae0015' }}>
                                {formik.errors.no_of_people}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Route of Arrival / આગમનનો માર્ગ
                            <span style={{ color: '#ae0015' }}>*</span>
                          </label>
                          <Select
                            name="arrival_route_id"
                            className={
                              formik?.errors.arrival_route_id &&
                              formik?.touched.arrival_route_id
                                ? 'is-invalid'
                                : 'valid'
                            }
                            options={sangList}
                            onBlur={() =>
                              formik?.setFieldTouched('arrival_route_id', true)
                            }
                            onChange={(opt) => {
                              formik.setFieldValue(
                                'arrival_route_id',
                                opt ? opt.value : ''
                              );
                            }}
                            value={
                              sangList.find(
                                (option) =>
                                  option.value ===
                                  formik.values.arrival_route_id
                              ) || null
                            }
                            isSearchable={true}
                          />
                          {formik.errors.arrival_route_id &&
                            formik.touched.arrival_route_id && (
                              <div
                                className="invalid-feedback"
                                style={{ color: '#ae0015' }}
                              >
                                {formik.errors.arrival_route_id}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Number of Vehicles / વાહન સંખ્યા
                            <span style={{ color: '#ae0015' }}>*</span>
                          </label>
                          <Select
                            name="no_of_vehicles"
                            className={
                              formik?.errors.no_of_vehicles &&
                              formik?.touched.no_of_vehicles
                                ? 'is-invalid'
                                : 'valid'
                            }
                            options={[
                              { value: '1', label: '1' },
                              { value: '2', label: '2' },
                              { value: '3', label: '3' },
                              { value: '4', label: '4' }
                            ]}
                            onBlur={() =>
                              formik?.setFieldTouched('no_of_vehicles', true)
                            }
                            onChange={(selectedOption) => {
                              const selectedVehicleCount = parseInt(
                                selectedOption.value,
                                10
                              );
                              setVehicleCount(selectedVehicleCount);
                              formik.handleChange({
                                target: {
                                  name: 'no_of_vehicles',
                                  value: selectedOption.value
                                }
                              });
                            }}
                            value={
                              formik.values.no_of_vehicles
                                ? {
                                    value: formik.values.no_of_vehicles,
                                    label: formik.values.no_of_vehicles
                                  }
                                : null
                            }
                            isSearchable={false}
                          />

                          {formik.touched.no_of_vehicles &&
                            formik.errors.no_of_vehicles && (
                              <div style={{ color: '#ae0015' }}>
                                {formik.errors.no_of_vehicles}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>

                    {/* hide vehicle field  */}
                    {vehicleCount > 0 && (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Vehicle Category / વાહન શ્રેણી
                              <span style={{ color: '#ae0015' }}>*</span>
                            </label>
                            <Select
                              name="vehicle1_category_id"
                              className={
                                formik?.errors.vehicle1_category_id &&
                                formik?.touched.vehicle1_category_id
                                  ? 'is-invalid'
                                  : 'valid'
                              }
                              options={vehicleCategoryList}
                              onBlur={() =>
                                formik?.setFieldTouched(
                                  'vehicle1_category_id',
                                  true
                                )
                              }
                              onChange={(opt) => {
                                formik.setFieldValue(
                                  'vehicle1_category_id',
                                  opt ? opt.value : ''
                                );
                              }}
                              value={
                                vehicleCategoryList.find(
                                  (option) =>
                                    option.value ===
                                    formik.values.vehicle1_category_id
                                ) || null
                              }
                              isSearchable={true}
                            />
                            {formik.errors.vehicle1_category_id &&
                              formik.touched.vehicle1_category_id && (
                                <div
                                  className="invalid-feedback"
                                  style={{ color: '#ae0015' }}
                                >
                                  {formik.errors.vehicle1_category_id}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Vehicle Number / વાહન નંબર{' '}
                              <span style={{ color: '#ae0015' }}>*</span>
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                formik.touched.vehicle1_number &&
                                (formik.errors.vehicle1_number || vehicleError1)
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              name="vehicle1_number"
                              value={formik.values.vehicle1_number} // Ensure this is always uppercase
                              onChange={(e) => {
                                const { name, value } = e.target;
                                const uppercasedValue = value.toUpperCase(); // Convert to uppercase
                                formik.setFieldValue(name, uppercasedValue); // Update Formik's value
                                const error =
                                  validateVehicleNumberOp1(uppercasedValue); // Validate the uppercase value
                                setVehicleError1(error || ''); // Update error state
                              }}
                              onBlur={formik.handleBlur}
                            />

                            {(formik.touched.vehicle1_number &&
                              formik.errors.vehicle1_number) ||
                            vehicleError1 ? (
                              <div style={{ color: '#ae0015' }}>
                                {formik.errors.vehicle1_number || vehicleError1}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    )}

                    {vehicleCount > 1 && (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Vehicle Category 2 / વાહન શ્રેણી 2
                              <span style={{ color: '#ae0015' }}>*</span>
                            </label>
                            <Select
                              name="vehicle2_category_id"
                              className={
                                formik?.errors.vehicle2_category_id &&
                                formik?.touched.vehicle2_category_id
                                  ? 'is-invalid'
                                  : 'valid'
                              }
                              options={vehicleCategoryList}
                              onBlur={() =>
                                formik?.setFieldTouched(
                                  'vehicle2_category_id',
                                  true
                                )
                              }
                              onChange={(opt) => {
                                formik.setFieldValue(
                                  'vehicle2_category_id',
                                  opt ? opt.value : ''
                                );
                              }}
                              value={
                                vehicleCategoryList.find(
                                  (option) =>
                                    option.value ===
                                    formik.values.vehicle2_category_id
                                ) || null
                              }
                              isSearchable={true}
                            />
                            {formik.errors.vehicle2_category_id &&
                              formik.touched.vehicle2_category_id && (
                                <div
                                  className="invalid-feedback"
                                  style={{ color: '#ae0015' }}
                                >
                                  {formik.errors.vehicle2_category_id}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Vehicle Number 2 / વાહન નંબર 2
                              <span style={{ color: '#ae0015' }}>*</span>
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                formik.touched.vehicle2_number &&
                                (formik.errors.vehicle2_number || vehicleError2)
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              name="vehicle2_number"
                              value={formik?.values.vehicle2_number}
                              onChange={(e) => {
                                const { name, value } = e.target;
                                const uppercasedValue = value.toUpperCase(); // Convert to uppercase
                                formik.setFieldValue(name, uppercasedValue); // Update Formik's value
                                const error =
                                  validateVehicleNumberOp2(uppercasedValue); // Validate the uppercase value
                                setVehicleError2(error || ''); // Update error state
                              }}
                              onBlur={formik.handleBlur}
                            />
                            {(formik.touched.vehicle2_number &&
                              formik.errors.vehicle2_number) ||
                            vehicleError2 ? (
                              <div style={{ color: '#ae0015' }}>
                                {formik.errors.vehicle2_number || vehicleError2}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    )}

                    {vehicleCount > 2 && (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Vehicle Category 3 / વાહન શ્રેણી 3
                              <span style={{ color: '#ae0015' }}>*</span>
                            </label>
                            <Select
                              name="vehicle3_category_id"
                              className={
                                formik?.errors.vehicle3_category_id &&
                                formik?.touched.vehicle3_category_id
                                  ? 'is-invalid'
                                  : 'valid'
                              }
                              options={vehicleCategoryList}
                              onBlur={() =>
                                formik?.setFieldTouched(
                                  'vehicle3_category_id',
                                  true
                                )
                              }
                              onChange={(opt) => {
                                formik.setFieldValue(
                                  'vehicle3_category_id',
                                  opt ? opt.value : ''
                                );
                              }}
                              value={
                                vehicleCategoryList.find(
                                  (option) =>
                                    option.value ===
                                    formik.values.vehicle3_category_id
                                ) || null
                              }
                              isSearchable={true}
                            />
                            {formik.errors.vehicle3_category_id &&
                              formik.touched.vehicle3_category_id && (
                                <div
                                  className="invalid-feedback"
                                  style={{ color: '#ae0015' }}
                                >
                                  {formik.errors.vehicle3_category_id}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Vehicle Number 3 / વાહન નંબર 3
                              <span style={{ color: '#ae0015' }}>*</span>
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                formik.touched.vehicle2_number &&
                                (formik.errors.vehicle2_number || vehicleError3)
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              name="vehicle3_number"
                              value={formik?.values.vehicle3_number}
                              onChange={(e) => {
                                const { name, value } = e.target;
                                const uppercasedValue = value.toUpperCase(); // Convert to uppercase
                                formik.setFieldValue(name, uppercasedValue); // Update Formik's value
                                const error =
                                  validateVehicleNumberOp3(uppercasedValue); // Validate the uppercase value
                                setVehicleError3(error || ''); // Update error state
                              }}
                              onBlur={formik.handleBlur}
                            />
                            {(formik.touched.vehicle3_number &&
                              formik.errors.vehicle3_number) ||
                            vehicleError3 ? (
                              <div style={{ color: '#ae0015' }}>
                                {formik.errors.vehicle3_number || vehicleError3}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    )}

                    {vehicleCount > 3 && (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Vehicle Category 4 / વાહન શ્રેણી 4
                              <span style={{ color: '#ae0015' }}>*</span>
                            </label>
                            <Select
                              name="vehicle4_category_id"
                              className={
                                formik?.errors.vehicle4_category_id &&
                                formik?.touched.vehicle4_category_id
                                  ? 'is-invalid'
                                  : 'valid'
                              }
                              options={vehicleCategoryList}
                              onBlur={() =>
                                formik?.setFieldTouched(
                                  'vehicle4_category_id',
                                  true
                                )
                              }
                              onChange={(opt) => {
                                formik.setFieldValue(
                                  'vehicle4_category_id',
                                  opt ? opt.value : ''
                                );
                              }}
                              value={
                                vehicleCategoryList.find(
                                  (option) =>
                                    option.value ===
                                    formik.values.vehicle4_category_id
                                ) || null
                              }
                              isSearchable={true}
                            />
                            {formik.errors.vehicle4_category_id &&
                              formik.touched.vehicle4_category_id && (
                                <div
                                  className="invalid-feedback"
                                  style={{ color: '#ae0015' }}
                                >
                                  {formik.errors.vehicle4_category_id}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Vehicle Number 4 / વાહન નંબર 4
                              <span style={{ color: '#ae0015' }}>*</span>
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                formik.touched.vehicle4_number &&
                                (formik.errors.vehicle4_number || vehicleError4)
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              name="vehicle4_number"
                              value={formik?.values.vehicle4_number}
                              pattern="/^[A-Z]{2}[ -][0-9]{1,2}(?: [A-Z])?(?: [A-Z]*)? [0-9]{4}$/"
                              onChange={(e) => {
                                const { name, value } = e.target;
                                const uppercasedValue = value.toUpperCase(); // Convert to uppercase
                                formik.setFieldValue(name, uppercasedValue); // Update Formik's value
                                const error =
                                  validateVehicleNumberOp4(uppercasedValue); // Validate the uppercase value
                                setVehicleError4(error || ''); // Update error state
                              }}
                              onBlur={formik.handleBlur}
                            />
                            {(formik.touched.vehicle4_number &&
                              formik.errors.vehicle4_number) ||
                            vehicleError4 ? (
                              <div style={{ color: '#ae0015' }}>
                                {formik.errors.vehicle4_number || vehicleError4}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    )}

                    {/* end  */}

                    <div className="row">
                      <label
                        style={{
                          marginBottom: '25px'
                        }}
                      >
                        <div className="d-flex">
                          <span>
                            <input
                              className="checkbox-boxes-sang"
                              type="checkbox"
                              id="accept-checkbox"
                              onChange={handleCheckboxChange}
                            />
                          </span>
                          <span>
                            <b>
                              અમારા દ્વારા ઉપરોક્ત તમામ માહિતી સાચી આપવામાં આવેલ
                              છે તેની હું બાંહેધરી આપું છું. ખોટી માહિતી આપવી એ
                              ગુનો છે તે હું જાણું છું.
                            </b>
                          </span>
                        </div>
                      </label>
                    </div>

                    {/* <button
                    className="orangebtn"
                    type="submit"
                    style={{ width: "100%" }}
                    disabled={!formik.isValid || formik.isSubmitting}
                  >
                    {formik.isSubmitting ? "Submitting..." : "Register"}
                  </button> */}

                    <button
                      className="orangebtn"
                      type="next"
                      style={{ width: '100%' }}
                      // onClick={handleNextButtonClick}
                    >
                      Register
                    </button>

                    <div className="backend-error">
                      <p style={{ color: '#ae0015' }}>{backendErrors}</p>
                    </div>
                  </form>
                </div>
              </div>
              {/* ) : null} */}
            </div>
          </div>
        </section>
        <Arasuri />
        <Footer />
      </div>
    </>
  );
};

export default Sang;
