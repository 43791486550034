// import LogOutConfirmationModal from "../Modules/Auth/LogOutConfirmationModal";
// import Cart from "../Modules/E_store/Cart/onlineShoppingCart";

export const routes = {
  // Navbar
  home: '/home',
  liveStream: '/ambaji-temple-gabbar-live-darshan',
  temple: '/about-ambaji-temple',
  gabbar: '/about-ambaji-gabbar',
  contactUs: '/contact-us',

  // 51 shakipith
  shakipith: '/51-shaktipeeth',
  shaktipithMahotsav: '/51-shaktipeeth-mahotsav',
  dailyRoutine: '/daily-routine',
  parkingSolution: '/parking-solution',
  parikramaSang: '/parikrama-sang',
  gabbarTaletiSang: '/gabbar-taleti-sang',

  // Auth
  login: '/login',
  signUp: '/signUp',
  forgotPassword: '/forgot-password',
  profile: '/user-profile',
  userProfile: '/profile',
  OTPverification: '/OTP-verification',

  // Cmponents
  otpVerification: '/otp-verification',

  // Online services
  onlineServices: '/ambaji-temple-online-booking-services',
  onlineShopping: '/online-ambaji-temple-shop',
  // onlineShoppingDetail: "/online-shopping-detail/",
  onlineShoppingDetail: '/online-shopping-detail/:productName/:id',
  mandirYagnaBooking: '/mandir-yagna-booking',

  // ---bhadarviPoonam
  bhadarviPoonam: '/bhadarvi-poonam',
  bhadarvipoonamreceiptdownload: '/bhadarvi-poonam-receipt-download',

  sevaTermsConditions: '/seva-terms-conditions',
  sevaCamp: '/seva-camp',
  sevaBookingReceipt: '/seva-camp-booking-receipt/:id',

  sangTermsConditions: '/sang-terms-conditions',
  sang: '/sang',
  sanghBookingReceipt: '/sangh-booking-receipt/:id',

  search: '/bhadarvi-search',

  employeVehiclePass: '/employee-vehicle-pass',
  empVehiclePassSuccess: '/employee-vehicle-pass/registration/success/:id',

  sthanikVehiclePass: '/ambaji-sthanik-vehicle-pass',
  sthanikVehiclePassSuccess:
    '/ambaji-sthanik-vehicle-pass/registration/success/:id',
  searchSthanikVehiclePass: '/search-ambaji-sthanik-vehicle-pass',
  searchSthanikVehiclePassSuccess:
    '/search-ambaji-sthanik-vehicle-pass/registration/success/:id',
  // Dhaja Bokking
  dhajaBooking: '/dhaja-booking',
  dhajaBookingReceipt: '/dhaja-booking-receipt/:id',

  // -------donation-------
  donationMoney: '/donation-money',
  donationMoneyReceipt: '/donation-money-receipt/:id',
  donationCounterMoneyReceipt: '/donation-counter-money-receipt/:id',
  donationArticleReceipt: '/donation-article-receipt/:id',

  donationGold: '/donation-gold',
  donationGoldReceipt: '/donation-gold-receipt/:id',
  donationCounterGoldReceipt: '/donation-counter-gold-receipt/:id',
  moneyDonationSuccess: '/money-donation/payment/success/:id',
  goldDonationSuccess: '/gold-donation/payment/success/:id',
  moneyDonationFalied: '/money-donation/payment/failed',
  // printDonationReceipt: "/print-payment-receipt",

  // e-store
  onlinePrasad: '/online-ambaji-temple-prasad',
  onlinePrasadSuccess: '/online-ambaji-temple-prasad/payment/success/:id',
  onlinePrasadFailed: '/online-ambaji-temple-prasad/payment/failed',
  onlinePrasadReceipt: '/online-ambaji-temple-prasad-receipt/:id',
  onlinePrasadDetail: '/online-ambaji-temple-prasad-detail/:prasad_name/:id',
  // orderPrasadReceipt: "/online-ambaji-website-prasad-receipt/:id",
  counterPrasadReceipt: '/counter-ambaji-temple-prasad-receipt/:id',

  // Cart
  Cart: '/cart',

  // Checkout
  checkout: '/checkout',

  // footer
  aboutUs: '/ambaji-temple-trust',
  privacyPolicy: '/privacy-policy',
  termsAndConditions: '/terms-and-conditions',
  shippingAndDelivery: '/shipping-and-delivery',
  news: '/news',
  newsDetails: '/news-details/:id',
  touristAttraction: '/tourist-attraction',
  touristAttractionDetails: '/tourist-attraction-details',

  yagnaBooking: '/51-shaktipeeth-parikrama-yagna-booking',
  shaktiDarshanam: '/shakti-darshanam',
  ShaktiDarshanamAddress: '/shakti-darshanam-address',

  photoGallery: '/photo-gallery',
  // SwayamSevak
  SwayamSevak: '/swayam-sevak-registration',
  swayamSevakReceipt: '/swayam-sevak-receipt/:id',

  // error page

  // error404: "/error404",
  error500: '/error500'
};
