import React from "react";
import { routes } from "../_Constants/routes";
import facebook from "../Assets/Images/Social media/facebook.svg";
import twiter from "../Assets/Images/Social media/twitter.svg";
import instagram from "../Assets/Images/Social media/instagram.svg";
import youtube from "../Assets/Images/Social media/youtube.svg";

import AddressIcon from "../Assets/Images/Footer/AddressIcon.svg";
import PhoneIcon from "../Assets/Images/Footer/PhoneIcon.svg";
import MailIcon from "../Assets/Images/Footer/MailIcon.svg";
import "../Css/footer.scss";
const Footer = () => {
  return (
    <>
      <footer>
        <div className="container">
          <div className="footer-wrapper">
            <div className="row justify-content-center">
              <div className="col-xl-3 col-lg-3 col-md-12 col-12">
                <h5>અમારા વિશે</h5>
                <div className="footer-info">
                  <p>
                    અંબાજી મંદિર ઉત્તર ગુજરાત ભારતના બનાસકાંઠા જિલ્લાના દાંતા
                    તાલુકામાં આવેલા અંબાજી શહેરમાં આવેલું છે. અરવલ્લીની સુંદર
                    ટેકરીઓ અંબાજી નગરને ઘેરી લે છે.
                  </p>
                </div>
                <ul className="coman-social">
                  <li className="facebook">
                    <a
                      href="https://www.facebook.com/ambajitempletrustofficial/"
                      target="_blank"
                    >
                      <img src={facebook} alt="facebook"></img>
                    </a>
                  </li>
                  <li className="twiter">
                    <a href="https://twitter.com/templeambaji" target="_blank">
                      <img src={twiter} alt="twiter"></img>
                    </a>
                  </li>
                  <li className="instagram">
                    <a
                      href="https://instagram.com/ambajitempletrustofficial"
                      target="_blank"
                    >
                      <img src={instagram} alt="instagram"></img>
                    </a>
                  </li>
                  <li className="youtube">
                    <a
                      href="https://youtube.com/@ambajitempleofficial3283"
                      target="_blank"
                    >
                      <img src={youtube} alt="youtube"></img>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-4 col-4">
                <h5>માહિતી</h5>
                <ul className="menu">
                  <li>
                    <a href={routes?.aboutUs}>અમારા વિશે</a>
                  </li>
                  <li>
                    <a href={routes?.touristAttraction}>
                      પર્યટકો માટે નું આકર્ષણ
                    </a>
                  </li>
                  <li>
                    <a href={routes?.shakipith}>પ૧ શક્તિપીઠ</a>
                  </li>
                  {/*  <li>
                    <a href={routes?.shaktipithMahotsav}>પ૧ શક્તિપીઠ મહોત્સવ</a>
                  </li>
                  <li><a href={routes?.shaktiDarshanam}>શક્તિ દર્શનમ્</a></li> */}
                  <li>
                    <a href={routes?.photoGallery}>ફોટો ગેલેરી</a>
                  </li>
                </ul>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-4 col-4">
                <h5>સેવાઓ</h5>
                <ul className="menu">
                  {/* <li>
                    <a href={routes?.mandirYagnaBooking}>મંદિર યજ્ઞ બુકિંગ</a>
                  </li>
                  <li>
                    <a href=" ">અન્નકૂટ બુકિંગ</a>
                  </li>
                  <li>
                    <a href=" ">પાવડી પૂજા બુકિંગ</a>
                  </li>
                  <li>
                    <a href={routes?.yagnaBooking}>૫૧ શક્તિપીઠ યજ્ઞ બુકિંગ</a>
                  </li> */}
                  <li>
                    <a href={routes?.bhadarviPoonam}>ભાદરવી પૂનમ રજિસ્ટ્રેશન</a>
                  </li>
                </ul>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-4 col-4">
                <h5>માહિતી / નીતિઓ</h5>
                <ul className="menu">
                  <li>
                    <a href={routes?.privacyPolicy}>ગોપનીયતા નીતિ</a>
                  </li>
                  <li>
                    <a href={routes?.termsAndConditions}>નિયમો અને શરતો</a>
                  </li>
                  <li>
                    <a href={routes?.shippingAndDelivery}>ડિલિવરી નીતિ</a>
                  </li>
                  <li>
                    <a href={routes?.news}>સમાચાર</a>
                  </li>
                </ul>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-12 col-12">
                <h5>અમારો સંપર્ક કરો</h5>
                <ul className="location-info">
                  <li>
                    <a
                      href="https://maps.app.goo.gl/N6LAqcGPTgHu5zy27"
                      style={{ paddingLeft: "0px" }}
                      target="_blank"
                    >
                      <img
                        src={AddressIcon}
                        style={{
                          width: "17px",
                          height: "auto",
                          marginRight: "10px",
                        }}
                      ></img>
                      Ambaji, Ta.Danta, Dist.B.k-385110
                    </a>
                  </li>
                  <li>
                    <a href="tel:02749-262236" style={{ paddingLeft: "0px" }}>
                      <img
                        src={PhoneIcon}
                        style={{
                          width: "17px",
                          height: "auto",
                          marginRight: "10px",
                        }}
                      ></img>
                      02749-262236
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:info@ambajitemple.in"
                      target="_blank"
                      style={{ paddingLeft: "0px" }}
                    >
                      <img
                        src={MailIcon}
                        style={{
                          width: "17px",
                          height: "auto",
                          marginRight: "10px",
                        }}
                      ></img>
                      info@ambajitemple.in
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="copyrightdiv">
            Copyright © <a href="#">Ambaji Temple</a> 2024. All Rights Reserved.
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

// import React from "react";
// import { routes } from "../_Constants/routes";
// import facebook from "../Assets/Images/Social media/facebook.svg";
// import twiter from "../Assets/Images/Social media/twitter.svg";
// import instagram from "../Assets/Images/Social media/instagram.svg";
// import youtube from "../Assets/Images/Social media/youtube.svg";

// import AddressIcon from "../Assets/Images/Footer/AddressIcon.svg";
// import PhoneIcon from "../Assets/Images/Footer/PhoneIcon.svg";
// import MailIcon from "../Assets/Images/Footer/MailIcon.svg";
// import "../Css/footer.scss";
// const Footer = () => {
//   return (
//     <footer>
//       <div className="container">
//         <div className="footer-wrapper">
//           <div className="row justify-content-center">
//             <div className="col-md-4">
//               <h5>અમારા વિશે</h5>
//               <div className="footer-info">
//                 <p>
//                   અંબાજી મંદિર ઉત્તર ગુજરાત ભારતના બનાસકાંઠા જિલ્લાના દાંતા
//                   તાલુકામાં આવેલા અંબાજી શહેરમાં આવેલું છે. અરવલ્લીની સુંદર
//                   ટેકરીઓ અંબાજી નગરને ઘેરી લે છે અને અંબાજી "શિખરોની લાઇન" માં
//                   વસેલું હોવાનું કહેવાય છે.
//                 </p>
//               </div>
//               <ul className="coman-social">
//                 <li className="facebook">
//                   <a
//                     href="https://www.facebook.com/ambajitempletrustofficial/"
//                     target="_blank"
//                   >
//                     <img src={facebook} alt="facebook"></img>
//                   </a>
//                 </li>
//                 <li className="twiter">
//                   <a href="https://twitter.com/templeambaji" target="_blank">
//                     <img src={twiter} alt="twiter"></img>
//                   </a>
//                 </li>
//                 <li className="instagram">
//                   <a
//                     href="https://instagram.com/ambajitempletrustofficial"
//                     target="_blank"
//                   >
//                     <img src={instagram} alt="instagram"></img>
//                   </a>
//                 </li>
//                 <li className="youtube">
//                   <a
//                     href="https://youtube.com/@ambajitempleofficial3283"
//                     target="_blank"
//                   >
//                     <img src={youtube} alt="youtube"></img>
//                   </a>
//                 </li>
//               </ul>
//             </div>
//             <div className="col-md-2 col-6">
//               <h5>માહિતી</h5>
//               <ul className="menu">
//                 <li>
//                   <a href={routes?.yagnaBooking}>યજ્ઞ બુકિંગ</a>
//                 </li>
//                 <li>
//                   <a href={routes?.aboutUs}>અમારા વિશે</a>
//                 </li>
//                 <li>
//                   <a href={routes?.touristAttraction}>
//                     પર્યટકો માટે નું આકર્ષણ
//                   </a>
//                 </li>
//                 <li>
//                   <a href={routes?.shakipith}>51 શક્તિપીઠ</a>
//                 </li>
//               </ul>
//             </div>
//             <div className="col-md-2 col-6">
//               <h5>માહિતી / નીતિઓ</h5>
//               <ul className="menu">
//                 <li>
//                   <a href={routes?.privacyPolicy}>ગોપનીયતા નીતિ</a>
//                 </li>
//                 <li>
//                   <a href={routes?.termsAndConditions}>નિયમો અને શરતો</a>
//                 </li>
//                 <li>
//                   <a href={routes?.shippingAndDelivery}>ડિલિવરી નીતિ</a>
//                 </li>
//                 <li>
//                   <a href={routes?.news}>સમાચાર</a>
//                 </li>
//               </ul>
//             </div>

//             {/* contact us  */}
//             <div className="col-md-4">
//               <h5>અમારો સંપર્ક કરો</h5>
//               <ul className="location-info">
//                 <li>
//                   <a
//                     href="https://maps.app.goo.gl/N6LAqcGPTgHu5zy27"
//                     style={{ paddingLeft: "0px" }}
//                     target="_blank"
//                   >
//                     {/* <i className="iicon-icon-14"></i> */}
//                     <img
//                       src={AddressIcon}
//                       style={{
//                         width: "17px",
//                         height: "auto",
//                         marginRight: "10px",
//                       }}
//                     ></img>
//                     Ambaji, Ta.Danta, Dist.B.k-385110
//                   </a>
//                 </li>
//                 <li>
//                   <a href="tel:02749-262236" style={{ paddingLeft: "0px" }}>
//                     {/* <i className="iicon-icon-3"></i> */}
//                     <img
//                       src={PhoneIcon}
//                       style={{
//                         width: "17px",
//                         height: "auto",
//                         marginRight: "10px",
//                       }}
//                     ></img>
//                     02749-262236
//                   </a>
//                 </li>
//                 <li>
//                   <a
//                     href="mailto:info@ambajitemple.in"
//                     target="_blank"
//                     style={{ paddingLeft: "0px" }}
//                   >
//                     {/* <i className="iicon-icon-4"></i> */}
//                     <img
//                       src={MailIcon}
//                       style={{
//                         width: "17px",
//                         height: "auto",
//                         marginRight: "10px",
//                       }}
//                     ></img>
//                     info@ambajitemple.in
//                   </a>
//                 </li>
//               </ul>
//             </div>
//             {/* ---------  */}

//           </div>
//         </div>

//         {/* copyright  */}
//         <div className="copyrightdiv">
//           Copyright © <a href="#">Ambaji Temple</a> 2023. All Rights Reserved.
//         </div>
//         {/* ---------  */}
//       </div>
//     </footer>
//   );
// };

// export default Footer;
