// fundamentals
import React, { useEffect, useState } from "react";

// components
import "./privacyPolicy.scss";
import Footer from "../../Components/footer";
import "../Home/home.scss";
import Arasuri from "../../Components/arasuri";
import LazyLoader from "../../Components/lazyLoader";

function PrivacyPolicy() {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [5000]);
  });
  return (
    <>
      {!isLoading ? (
        <div className="main-wrapper">
          {/* banner  */}
          <section className="header-banner temple-banner">
            <div className="container">
              <h1>ગોપનીયતા નીતિ</h1>
            </div>
          </section>
          {/* ----------  */}

          {/* details  */}
          <div className="privacy-policy">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="privacy-text">
                    <h2>ડેટા વપરાશ :</h2>
                    <ul>
                      <li>
                        અંબાજી મંદિર સેવાઓ સાથે સંબંધિત પર્સનલ માહિતિને મૂકવા
                        માટે ઓછી પર્યાપ્ત માહિતી જમાઈ છે.
                      </li>
                      <li>
                        ડેટા જે માટે પ્રદાન થઈ છે, તે મુકાબલામાં માટે વપરાશ થાય
                        છે.
                      </li>
                    </ul>
                  </div>
                  <div className="privacy-text">
                    <h2>ડિજિટલ એસેટ્સ :</h2>
                    <ul>
                      <li>
                        <a href="https://ambajitemple.in/" target="_blank">
                          www.ambajitemple.in
                        </a>{" "}
                        પરના બધા ડિજિટલ એસેટ્સ, સહિત ચિત્રો અને ટેક્સ્ટ, અંબાજી
                        મંદિર ટ્રસ્ટની સંપત્તિ છે.
                      </li>
                      <li>અનધિકૃત વપરાશ અથવા પુનરુત્પાદન નિષેધ છે.</li>
                    </ul>
                  </div>
                  <div className="privacy-text">
                    <h2>અખંડતા :</h2>
                    <ul>
                      <li>
                        અંબાજી મંદિર ટ્રસ્ટ વેબસાઇટ પરની માહિતિને સારવાર અને
                        પૂર્ણતાનું ધ્યાનમાં રાખવાનો પ્રયાસ કરે છે.
                      </li>
                      <li>
                        યૂઝર્સને તટસ્થ સુધારણા માટે કોઈ અશક્તિ રિપોર્ટ કરવાનું
                        પ્રમોતન કરાયું છે.
                      </li>
                    </ul>
                  </div>
                  <div className="privacy-text">
                    <h2>ગુપ્તતા :</h2>
                    <ul>
                      <li>
                        યૂઝર ડેટાને અમારી સાથે વિશેષ ગુપ્તતાના દૃષ્ટિકોણથી
                        ચાલાવવામાં આવે છે અને તેને વિના વાપરાશ કરવામાં આવતું
                        નથી.
                      </li>
                    </ul>
                  </div>
                  <div className="privacy-text">
                    <h2>ડેટા શેરિંગ :</h2>
                    <ul>
                      <li>
                        વ્યક્તિગત માહિતિ સિવાય વધારે ડેટાને બહાર જાહેરાતો, કાનૂન
                        દ્વારા આવશ્યક છે અથવા યૂઝરની સ્વિકૃતિ સાથે.
                      </li>
                    </ul>
                  </div>
                  <div className="privacy-text">
                    <h2>કોપિરાઇટ :</h2>
                    <ul>
                      <li>વેબસાઇટ પરનો તમામ વિષય કોપિરાઇટ કાનૂનોના અધીન છે.</li>
                      <li>
                        યૂઝર્સને કોપિરાઇટ નિયમોને માન્યતામાં લેવું અનિવાર્ય છે.
                      </li>
                    </ul>
                  </div>
                  <div className="privacy-text">
                    <h2>ઇન્ટેલેક્ચ્યુઅલ પ્રોપર્ટી :</h2>
                    <ul>
                      <li>
                        સંપત્તિના અધિકારો વિશેની સંપત્તિ, ડિઝાઇન અને કાર્યકલાપ
                        સહિત વેબસાઇટ પરના સર્વ સામગ્રી અંબાજી મંદિર ટ્રસ્ટના
                        અંતર્ગત છે.{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="privacy-text">
                    <h2>કુકીઝ :</h2>
                    <ul>
                      <li>
                        બેહતર યૂઝર અનુભવ અને વેબસાઇટ ટ્રેફિક વિશે માહિતી જોવા
                        માટે અમે કુકીઝનો ઉપયોગ કરીએ છીએ. યૂઝર્સ કુકીઝ પસંદગીઓ
                        વ્યવસ્થાપિત કરી શકે છે.
                      </li>
                    </ul>
                  </div>
                  <div className="privacy-text">
                    <h2>સુરક્ષા ઉપાયો :</h2>
                    <ul>
                      <li>
                        અનધિકૃત પહોંચ અથવા બદલાવથી યૂઝર ડેટાને સાચું રાખવા માટે
                        ઠંડુ સુરક્ષા ઉપાયો લાગુ કરવામાં આવવામાં આવે છે.
                      </li>
                    </ul>
                  </div>
                  <div className="privacy-text">
                    <h2>પ્રાઇવેસી પોલિસીની અપડેટ્સ :</h2>
                    <ul>
                      <li>
                        પ્રાઇવેસી પોલિસી અપડેટ થવાનો અધિકાર રાખવામાં આવે છે, અને
                        યૂઝર્સને કોઈ પરિવર્તનો સુચવવામાં આવશે.
                      </li>
                    </ul>
                  </div>
                  <div className="privacy-text">
                    <p>
                      <a href="https://ambajitemple.in/" target="_blank">
                        www.ambajitemple.in
                      </a>{" "}
                      ના ઉપયોગથી, યૂઝર્સ આ શરતોને માન્યતામાં લેવું માટે સંમત થાય
                      છે. કોઈ પ્રશ્નઓ અથવા ચિંતાઓ માટે,{" "}
                      <a href="mailto:info@ambajitemple.in" target="_blank">
                        info@ambajitemple.in
                      </a>{" "}
                      સાથે સંપર્ક કરો.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Arasuri />
          <Footer />
          {/* ----------  */}
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
}

export default PrivacyPolicy;
