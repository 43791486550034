import React, { useState } from "react";
import OTPInput from "react-otp-input";
import "../Css/otpVerification.scss";

const OtpVerification = ({ onOtpVerified }) => {
  const [enteredOtp, setEnteredOtp] = useState("");
  const [isOtpVerified, setIsOtpVerified] = useState(false);

  const [otp, setOtp] = useState("");
  const [config, setConfig] = useState({
    otp: "",
    numInputs: 6,
    separator: "\u00a0",
    minLength: 0,
    maxLength: 40,
    placeholder: "",
    inputType: "text",
  });

  const handleOTPChange = (otp) => {
    // setConfig((prevConfig) => ({ ...prevConfig, otp }));
    setEnteredOtp(otp);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setConfig((prevConfig) => ({ ...prevConfig, [name]: value }));
  };

  const handleNumInputsChange = (event) => {
    let numInputs = event.target.valueAsNumber;

    if (numInputs < config.minLength || numInputs > config.maxLength) {
      numInputs = 6;

      console.error(
        `Please enter a value between ${config.minLength} and ${config.maxLength}`,
      );
    }

    setConfig((prevConfig) => ({ ...prevConfig, numInputs }));
  };

  const clearOtp = () => {
    setConfig((prevConfig) => ({ ...prevConfig, otp: "" }));
  };

  const handleSubmit = (event) => {
    // event.preventDefault();
    // alert(config.otp);

    if (otp === "123456") {
      onOtpVerified(); // Call the callback function to notify parent about OTP verification
    }
  };

  const handleSendOtpClick = async () => {
    try {
      // Set the enteredOtp to the static OTP value "123456"
      setEnteredOtp("123456");

      // Simulate OTP verification success
      setIsOtpVerified(true);
      // Handle successful OTP verification
    } catch (error) {
      // Handle error if needed
      console.error("Error:", error);
    }
  };

  return (
    <div className="container">
      <div className="side-bar">
        <a
          href="https://github.com/devfolioco/react-otp-input"
          target="_blank"
          rel="noreferrer"
        >
          {/* <div className="side-bar__segment side-bar__segment--header">
            <h2>react-otp-input</h2>
          </div> */}
        </a>
      </div>
      <div className="view">
        <div className="card">
          <form onSubmit={handleSubmit}>
            <p>Enter verification code</p>
            <div className="margin-top--small">
              <OTPInput
                inputStyle="inputStyle"
                numInputs={6}
                // numInputs={config.numInputs}
                onChange={handleOTPChange}
                renderSeparator={<span>{config.separator}</span>}
                // value={config.otp}
                value={enteredOtp}
                placeholder={config.placeholder}
                inputType={config.inputType}
                renderInput={(props) => <input {...props} />}
                shouldAutoFocus
              />
            </div>
            <div className="btn-row">
              <button
                className=" btn margin-top--large"
                type="button"
                disabled={config.otp.trim() === ""}
                onClick={clearOtp}
              >
                Clear
              </button>
              <button
                className="btn margin-top--large get-otp"
                // disabled={config.otp.length < config.numInputs}
                disabled={otp.length !== 6}
                onClick={handleSubmit}
              >
                Get OTP
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OtpVerification;
