// fundamentals
import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink
} from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FaCartPlus, FaUserCircle, FaLock } from 'react-icons/fa';
import { FiLogOut } from 'react-icons/fi';
import Modal from 'react-modal';
// import {
//   Login,
//   SignUp,
//   Shaktipith,
//   AboutUs,
//   BhadarviPoonam,
//   Profile,
//   ForgotPassword,
//   Booking,
//   BookingConfirmation,
//   YagnaBookingFailed,
//   MoneyDonationSuccess,
//   MoneyDonationFailed,
//   ViewBlog,
//   OrderDetails,
//   OrderFailed,
//   OTPVerification,
//   LogOutConfirmationModal,
//   OrderPaymentReceipt,
//   ShaktipithMahotsav,
// } from "../Modules";

// css
import '../Css/header.scss';
import '../Css/variable.scss';
import '../Css/global.scss';

// images
import logo from '../Assets/Images/Logo/logo.png';

// components
import Home from '../Modules/Home/home';
import ScrollToTop from './scrollToTop';

import { routes } from '../_Constants/routes';
import MobileHome from '../Modules/Home/mobileHome';
import Arasuri from './arasuri';
import DonationGold from '../Modules/Donation/Donation_gold/donationGold';
import DonationMoney from '../Modules/Donation/Donation_money/donationMoney';
import OnlinePrasad from '../Modules/E_store/Online_prasad/onlinePrasad';
import OnlineShopping from '../Modules/E_store/Online_shopping/onlineShopping';
import ParikramaSang from '../Modules/51_shaktipith/Parikrama_sang/parikramaSang';
import Temple from '../Modules/Temple/temple';
import Gabbar from '../Modules/Gabbar/gabbar';
import AboutUs from '../Modules/About_us/aboutUs';
import PrivacyPolicy from '../Modules/Privacy_policy/privacyPolicy';
import TermsAndConditions from '../Modules/Terms_and_conditions/termsAndConditions';
import ShippingAndDelivery from '../Modules/Shipping_and_delivery/shippingAndDelivery';
import News from '../Modules/News/news';
import NewsDetails from '../Modules/News/newsDetails';
import TouristAttraction from '../Modules/Tourist_attraction/Tourist_attraction/touristAttraction';
import TouristAttractionDetails from '../Modules/Tourist_attraction/Tourist_attraction_details/touristAttractionDetails';
import ContactUs from '../Modules/Contact_us/contactUs';
import Shaktipith from '../Modules/51_shaktipith/51_shakipith/51Shaktipith';
import YagnaBooking from '../Modules/yagna_booking/yagnaBooking';
import GabbarTaletiSangh from '../Modules/Gabbar_taleti_sang/gabbarTaletiSang';
import OnlinePrasadDetail from '../Modules/E_store/Online_prasad/onlinePrasadDetail';
import OnlineShoppingDetail from '../Modules/E_store/Online_shopping/onlineShoppingDetail';
import Login from '../Modules/Auth/Login';
import SignUp from '../Modules/Auth/signUp';
import OtpVerification from './otpVerification';
import ForgotPassword from '../Modules/Auth/forgotPassword';
import Profile from '../Modules/Profile/profile';
import UserProfile from '../Modules/Profile/UserProfile/userProfile';
import Checkout from '../Modules/E_store/Checkout/checkout';
import ShaktiDarshanam from '../Modules/Shakti_darshanam/shaktiDarshanam';
import ShaktiDarshanamAddress from '../Modules/Shakti_darshanam/Shakti_darshanam_address/shaktiDarshanamAddress';
import LiveStream from '../Modules/Live_stream/liveStream';
import OnlineServices from '../Modules/E_store/Online_services/onlineServices';
import MandirYagnaBooking from '../Modules/yagna_booking/mandir_yagna_booking/mandirYagnaBooking';
import ShaktipithMahotsav from '../Modules/51_shaktipith/51_shaktipith_mahotsav/51ShaktipithMahotsav';
import DailyRoutine from '../Modules/51_shaktipith/51_shaktipith_mahotsav/Daily_routine/dailyRoutine';
import ParkingSolution from '../Modules/51_shaktipith/51_shaktipith_mahotsav/Parking_solution/parkingSolution';
import BhadarviPoonam from '../Modules/Bhadarvi_poonam/bhadarviPoonam';
import SevaTermsConditions from '../Modules/Bhadarvi_poonam/Seva_terms_conditions/sevaTermsConditions';
import SevaCamp from '../Modules/Bhadarvi_poonam/Seva_form/sevaCamp';
import SangTermsConditions from '../Modules/Bhadarvi_poonam/Sang_terms_conditions/sangTermsConditions';
import SevaBookingReceipt from '../Modules/Bhadarvi_poonam/Seva_booking_receipt/sevaBookingReceipt';
import Sang from '../Modules/Bhadarvi_poonam/Sang_form/sang';
import SanghBookingReceipt from '../Modules/Bhadarvi_poonam/Sangh_booking_receipt/sanghBookingReceipt';
import DhajaBooking from '../Modules/Dhaja_booking/dhajaBooking';
import DhajaBookingReceipt from '../Modules/Dhaja_booking/Dhaja_booking_receipt/dhajaBookingReceipt';
import PhotoGallery from '../Modules/Photo_gallery/photoGallery';
import Cart from '../Modules/E_store/Cart/onlineShoppingCart';
import LogOutConfirmationModal from '../Modules/Auth/logOutConfirmationModal';
import OTPVerification from '../Modules/Auth/OTPVerification';
import MoneyDonationSuccess from '../Modules/Donation/payment-status/MoneyDonationSuccess';

import DonationGoldReceipt from '../Modules/Donation/Donation_gold_receipt/donationGoldReceipt';
import DonationMoneyReceipt from '../Modules/Donation/Donation_money_receipt/donationMoneyReceipt';
import DonationCounterMoneyReceipt from '../Modules/Donation/Donation_counter_money_receipt/donationCounterMoneyReceipt';
import DonationArticleReceipt from '../Modules/Donation/Donation__article _receipt/donationArticleReceipt';

import MoneyDonationFailed from '../Modules/Donation/payment-status/MoneyDonationFailed';
import GoldDonationSuccess from '../Modules/Donation/payment-status/GoldDonationSuccess';
import OnlinePrasadReceipt from '../Modules/E_store/Online_prasad/onlinePrasadReceipt';
import CounterPrasadReceipt from '../Modules/E_store/Online_prasad/counterPrasadReceipt';
import OnlinePrasadSuccess from '../Modules/E_store/payment-status/onlinePrasadSuccess';
import OnlinePrasadFailed from '../Modules/E_store/payment-status/onlinePrasadFailed';
import Search from '../Modules/Bhadarvi_poonam/Search/search';
import DonationCounterGoldReceipt from '../Modules/Donation/Donation_counter_gold_receipt/donationCounterGoldReceipt';
import Error404 from '../Pages/error404';
import Error500 from '../Pages/error500';
import EmployeeVehiclePass from '../Modules/Bhadarvi_poonam/employeVehiclePass_form/employeVehiclePass';
import EmployeeVehiclePassService from '../Api_config/Bhadarvi_poonam/employeevehiclepass';
import EmployeeVehiclePassSuccess from '../Modules/Bhadarvi_poonam/employeVehiclePass_form/empVehiclePassSuccess';
import SwayamSevak from '../Modules/Swayam_sevak/Swayamsevak_form/swayamSevak';
import SwayamSevakReceipt from '../Modules/Swayam_sevak/Swayamsevak_receipt/swayamsevakReceipt';
import BhadarviPoonamReceiptDownload from '../Modules/Bhadarvi_poonam/BhadarviPoonamReceiptDownload/bhadarvipoonamreceiptdownload';
import SthanikVehiclePass from '../Modules/Bhadarvi_poonam/sthanikVehiclePass_form/sthanikVehiclePass';
import SthanikVehiclePassSuccess from '../Modules/Bhadarvi_poonam/sthanikVehiclePass_form/sthanicVehiclePassSuccess';
import SearchSthanikVehiclePass from '../Modules/Bhadarvi_poonam/searchSthanikVehiclePass_form/searchSthanikVehiclePass';
import SearchSthanikVehiclePassSuccess from '../Modules/Bhadarvi_poonam/searchSthanikVehiclePass_form/searchSthanicVehiclePassSuccess';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '400px',
    padding: '0px',
    border: 'none'
  }
};

const Header = () => {
  const [isToggleActive, setIsToggleActive] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const url = window.location.href;
  const lastSegment = url.split('/').pop();
  const [fix, setFix] = useState(false);
  function setFixed() {
    if (window.scrollY >= 130) {
      setFix(true);
    } else {
      setFix(false);
    }
  }

  useEffect(() => {
    localStorage.setItem('accepted', false);
  }, []);

  window.addEventListener('scroll', setFixed);
  const [btnState, setBtnState] = useState(false);
  function handleClick() {
    setBtnState((btnState) => !btnState);
  }

  const handleHomeLinkClick = () => {
    setIsToggleActive(false); // Close the sidebar when Home link is clicked
  };

  // const logout = () => {
  //     localStorage.removeItem('token');
  //     localStorage.removeItem('user_id');
  //     setOpenModal(false)
  //     navigate('/login')
  // }

  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src =
  //     "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
  //   script.async = true;
  //   document.body.appendChild(script);

  //   script.onload = () => {
  //     // Script loaded, initialize translation element
  //     window.googleTranslateElementInit = () => {
  //       new window.google.translate.TranslateElement(
  //         {
  //           pageLanguage: "gu",
  //           includedLanguages: "en,gu",
  //           layout:
  //             window.google.translate.TranslateElement.InlineLayout.SIMPLE,
  //           autoDisplay: true,
  //         },
  //         "google_translate_element",
  //       );
  //     };
  //   };

  //   return () => {
  //     // Cleanup: remove the script from the body
  //     document.body.removeChild(script);
  //   };
  // }, []);

  return (
    <>
      <div className="wrapper">
        <Router>
          <header
            className={fix ? 'header fixed' : 'header'}
            id="non-printable"
          >
            <div
              id="google_translate_element"
              className="d-block d-lg-block"
            ></div>
            <div className={lastSegment === '' ? 'home' : ''}>
              <div className="container">
                <div className="header-wrapper">
                  <div className="header-logo">
                    <NavLink to="/">
                      <img
                        src={logo}
                        alt="Ambaji Temple Banaskatha Gujarat"
                      ></img>
                    </NavLink>
                  </div>
                  <div className="header-right">
                    <nav className={isToggleActive ? 'toggle_open' : ''}>
                      <ul>
                        <li>
                          <NavLink
                            to="/"
                            activeclassname="active"
                            onClick={handleHomeLinkClick}
                          >
                            હોમ
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to={routes?.bhadarviPoonam}
                            activeclassname="active"
                            onClick={handleHomeLinkClick}
                          >
                            <b>ભાદરવી પૂનમ ૨૦૨૪</b>
                          </NavLink>
                        </li>
                        {/* <li>
      <NavLink
        to={routes?.bhadarviPoonam}
        activeclassname="active"
        onClick={handleHomeLinkClick}
      >
        ભાદરવી પૂનમ 2023
      </NavLink>
    </li> */}
                        <li>
                          <NavLink
                            to={routes?.liveStream}
                            activeclassname="active"
                            onClick={handleHomeLinkClick}
                          >
                            લાઈવ દર્શન
                            <span>
                              <sup className="blob"></sup>
                            </span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to={routes?.temple}
                            activeclassname="active"
                            onClick={handleHomeLinkClick}
                          >
                            મંદિર
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to={routes?.gabbar}
                            activeclassname="active"
                            onClick={handleHomeLinkClick}
                          >
                            ગબ્બર
                          </NavLink>
                        </li>

                        {/* <li>
                          <NavLink
                            to={routes?.gabbarTaletiSang}
                            activeclassname="active"
                            onClick={handleHomeLinkClick}
                          >
                            <strong>પરિક્રમા સંઘ નોંધણી</strong>
                          </NavLink>
                        </li> */}

                        {/* <li>
                          <Link
                            to="/"
                            className="dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            ઓનલાઇન સેવાઓ
                          </Link>
                          <ul className="mt-2 custom-dropdown-menu dropdown-menu dropdown-menu-bottom">
                            <li>
                              <Link
                                to={routes?.onlinePrasad}
                                activeclassname="active"
                                onClick={handleHomeLinkClick}
                              >
                                ઓનલાઈન પ્રસાદ
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={routes?.onlineShopping}
                                activeclassname="active"
                                onClick={handleHomeLinkClick}
                              >
                                ઓનલાઇન ખરીદી
                              </Link>
                            </li>
                          </ul>
                        </li> */}

                        <li>
                          <NavLink
                            to={routes?.onlinePrasad}
                            activeclassname="active"
                            onClick={handleHomeLinkClick}
                          >
                            ઓનલાઈન પ્રસાદ
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to={routes?.onlineServices}
                            activeclassname="active"
                            onClick={handleHomeLinkClick}
                          >
                            ઓનલાઇન સેવાઓ
                          </NavLink>
                        </li>

                        <li>
                          <NavLink
                            to={routes?.contactUs}
                            activeclassname="active"
                            onClick={handleHomeLinkClick}
                          >
                            અમારો સંપર્ક કરો
                          </NavLink>
                        </li>
                        {/* <li style={{ marginRight: "20px", marginTop: "2px" }}>
                          <NavLink
                            to={routes?.Cart}
                            activeclassname="active"
                            onClick={handleHomeLinkClick}
                            title="શોપિંગ કાર્ટ"
                          >
                            <FaCartPlus className="d-none d-lg-block" />
                            <span className="d-lg-none">શોપિંગ કાર્ટ</span>
                          </NavLink>
                        </li> */}

                        <li style={{ marginRight: '20px', marginTop: '2px' }}>
                          <NavLink
                            to={routes?.profile}
                            activeclassname="active"
                            onClick={handleHomeLinkClick}
                            title="પ્રોફાઇલ"
                          >
                            <FaUserCircle className="d-none d-lg-block" />
                            <span className="d-lg-none">પ્રોફાઇલ</span>
                          </NavLink>
                        </li>

                        {/* <li style={{ marginRight: "20px", marginTop: "2px" }}>
                          <FiLogOut
                            title="લોગ આઉટ"
                            color="white"
                            activeclassname="active"
                            onClick={() => setOpenModal(true)}
                            className="d-none d-lg-block"
                          />
                        </li> */}

                        {/* {localStorage.getItem("token") && (
                          <li className="d-block d-lg-none">
                           
                            <Link onClick={() => setOpenModal(true)}>
                              લોગ આઉટ
                            </Link>
                          </li>
                        )} */}
                      </ul>
                    </nav>

                    {/* donation buttons  */}
                    <div className="d-lg-block button-close dropdown">
                      <button
                        className="whitebtn dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        દાન
                      </button>
                      <ul className="mt-2 custom-dropdown-menu dropdown-menu">
                        <li>
                          <Link
                            to={routes?.donationMoney}
                            className="dropdown-item"
                          >
                            પૈસા
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={routes?.donationGold}
                            className="dropdown-item"
                          >
                            સોનું
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="d-none d-lg-block dropdown">
                      <button
                        className="whitebtn dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        દાન
                      </button>
                      <ul className="mt-2 custom-dropdown-menu dropdown-menu">
                        <li>
                          <Link
                            to={routes?.donationMoney}
                            className="dropdown-item"
                          >
                            પૈસા
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={routes?.donationGold}
                            className="dropdown-item"
                          >
                            સોનું
                          </Link>
                        </li>
                      </ul>
                    </div>
                    {/* ------------------  */}

                    <div
                      className="toggle"
                      onClick={() =>
                        isToggleActive
                          ? setIsToggleActive(false)
                          : setIsToggleActive(true)
                      }
                    >
                      <span
                        className={
                          isToggleActive
                            ? 'close-line-toggle line-toggle'
                            : 'line-toggle'
                        }
                      ></span>
                      <span
                        className={
                          isToggleActive
                            ? 'close-line-toggle line-toggle'
                            : 'line-toggle'
                        }
                      ></span>
                      <span
                        className={
                          isToggleActive
                            ? 'close-line-toggle line-toggle'
                            : 'line-toggle'
                        }
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>

          {/* logout confirmation modal  */}
          <Modal
            isOpen={openModal}
            onRequestClose={() => setOpenModal(false)}
            style={customStyles}
            shouldCloseOnOverlayClick={false}
            contentLabel="Example Modal"
          >
            <LogOutConfirmationModal closeModal={() => setOpenModal(false)} />
          </Modal>
          {/* ---------------------------  */}

          <ScrollToTop />
          <Routes>
            {/* -----modules----  */}
            {/* home */}
            <Route path="" element={<Home />} />
            <Route path={routes?.home} element={<Home />} />
            <Route path={routes?.mobileHome} element={<MobileHome />} />
            <Route path={routes?.liveStream} element={<LiveStream />} />
            {/* temple */}
            <Route path={routes?.temple} element={<Temple />} />
            <Route path={routes?.gabbar} element={<Gabbar />} />
            {/* 51 shakipith */}
            <Route path={routes?.shakipith} element={<Shaktipith />} />
            <Route path={routes?.dailyRoutine} element={<DailyRoutine />} />
            <Route
              path={routes?.parkingSolution}
              element={<ParkingSolution />}
            />
            <Route
              path={routes?.shaktipithMahotsav}
              element={<ShaktipithMahotsav />}
            />
            <Route
              path={routes?.gabbarTaletiSang}
              element={<GabbarTaletiSangh />}
            />
            {/* tourist attraction */}
            <Route
              path={routes?.touristAttraction}
              element={<TouristAttraction />}
            />
            <Route
              path={routes?.touristAttractionDetails}
              element={<TouristAttractionDetails />}
            />
            {/* Auth  */}
            <Route path={routes?.login} element={<Login />} />
            <Route path={routes?.signUp} element={<SignUp />} />
            <Route path={routes?.forgotPassword} element={<ForgotPassword />} />
            <Route path={routes?.profile} element={<Profile />} />
            <Route path={routes?.userProfile} element={<UserProfile />} />
            <Route
              path={routes?.OTPverification}
              element={<OTPVerification />}
            />
            {/* donation  */}
            <Route path={routes?.donationGold} element={<DonationGold />} />

            <Route
              path={routes?.donationGoldReceipt}
              element={<DonationGoldReceipt />}
            />
            <Route path={routes?.donationMoney} element={<DonationMoney />} />
            <Route
              path={routes?.donationCounterMoneyReceipt}
              element={<DonationCounterMoneyReceipt />}
            />
            <Route
              path={routes?.donationCounterGoldReceipt}
              element={<DonationCounterGoldReceipt />}
            />
            <Route
              path={routes?.donationMoneyReceipt}
              element={<DonationMoneyReceipt />}
            />
            <Route
              path={routes?.moneyDonationSuccess}
              element={<MoneyDonationSuccess />}
            />
            <Route
              path={routes?.goldDonationSuccess}
              element={<GoldDonationSuccess />}
            />
            <Route
              path={routes?.moneyDonationFalied}
              element={<MoneyDonationFailed />}
            />
            <Route
              path={routes?.donationArticleReceipt}
              element={<DonationArticleReceipt />}
            />

            {/* Online services  */}
            <Route path={routes?.onlineServices} element={<OnlineServices />} />
            <Route path={routes?.bhadarviPoonam} element={<BhadarviPoonam />} />
            <Route
              path={routes?.bhadarvipoonamreceiptdownload}
              element={<BhadarviPoonamReceiptDownload />}
            />
            <Route
              path={routes?.sevaTermsConditions}
              element={<SevaTermsConditions />}
            />
            <Route path={routes?.sevaCamp} element={<SevaCamp />} />

            <Route
              path={routes?.sevaBookingReceipt}
              element={<SevaBookingReceipt />}
            />

            <Route
              path={routes?.sangTermsConditions}
              element={<SangTermsConditions />}
            />
            <Route
              path={routes?.employeVehiclePass}
              element={<EmployeeVehiclePass />}
            />
            <Route
              path={routes?.empVehiclePassSuccess}
              element={<EmployeeVehiclePassSuccess />}
            />
            <Route
              path={routes?.sthanikVehiclePass}
              element={<SthanikVehiclePass />}
            />
            <Route
              path={routes?.sthanikVehiclePassSuccess}
              element={<SthanikVehiclePassSuccess />}
            />
            <Route
              path={routes?.searchSthanikVehiclePass}
              element={<SearchSthanikVehiclePass />}
            />
            <Route
              path={routes?.searchSthanikVehiclePassSuccess}
              element={<SearchSthanikVehiclePassSuccess />}
            />
            <Route path={routes?.sang} element={<Sang />} />

            <Route path={routes?.search} element={<Search />} />
            <Route
              path={routes?.sanghBookingReceipt}
              element={<SanghBookingReceipt />}
            />
            <Route
              path={routes?.mandirYagnaBooking}
              element={<MandirYagnaBooking />}
            />
            {/* SWayam Sevak */}
            <Route path={routes?.SwayamSevak} element={<SwayamSevak />} />
            <Route
              path={routes?.swayamSevakReceipt}
              element={<SwayamSevakReceipt />}
            />
            {/* e-store  */}
            <Route path={routes?.onlinePrasad} element={<OnlinePrasad />} />
            <Route path={routes?.Cart} element={<Cart />} />
            <Route
              path={routes?.onlinePrasadDetail}
              // path={`${routes?.onlinePrasadDetail}:prasad_name`}
              // path={`${routes?.onlinePrasadDetail}:prasad_name/:id`}
              element={<OnlinePrasadDetail />}
            />
            <Route
              path={routes?.onlinePrasadReceipt}
              element={<OnlinePrasadReceipt />}
            />
            <Route
              path={routes?.counterPrasadReceipt}
              element={<CounterPrasadReceipt />}
            />
            <Route
              path={routes?.onlinePrasadSuccess}
              element={<OnlinePrasadSuccess />}
            />
            <Route
              path={routes?.onlinePrasadFailed}
              element={<OnlinePrasadFailed />}
            />

            <Route path={routes?.onlineShopping} element={<OnlineShopping />} />
            <Route
              path={routes?.onlineShoppingDetail}
              // path={`${routes?.onlineShoppingDetail}:productId`}
              // path={`${routes?.onlineShoppingDetail}:productName`}
              element={<OnlineShoppingDetail />}
            />
            <Route path={routes?.checkout} element={<Checkout />} />
            {/* footer */}
            <Route path={routes?.aboutUs} element={<AboutUs />} />
            <Route path={routes?.privacyPolicy} element={<PrivacyPolicy />} />
            <Route
              path={routes?.termsAndConditions}
              element={<TermsAndConditions />}
            />
            <Route
              path={routes?.shippingAndDelivery}
              element={<ShippingAndDelivery />}
            />
            <Route path={routes?.news} element={<News />} />
            <Route path={routes?.newsDetails} element={<NewsDetails />} />
            <Route path={routes?.yagnaBooking} element={<YagnaBooking />} />
            <Route path={routes?.photoGallery} element={<PhotoGallery />} />
            <Route
              path={routes?.shaktiDarshanam}
              element={<ShaktiDarshanam />}
            />
            <Route
              path={routes?.ShaktiDarshanamAddress}
              element={<ShaktiDarshanamAddress />}
            />
            <Route path={routes?.dhajaBooking} element={<DhajaBooking />} />
            <Route
              path={routes?.dhajaBookingReceipt}
              element={<DhajaBookingReceipt />}
            />

            {/* components */}
            <Route path={routes?.arasuri} element={<Arasuri />} />
            <Route
              path={routes?.otpVerification}
              element={<OtpVerification />}
            />
            {/* contactUs */}
            <Route path={routes?.contactUs} element={<ContactUs />} />

            {/* error page */}

            <Route path="*" element={<Error404 />} />
            <Route path={routes?.error500} element={<Error500 />} />
          </Routes>
        </Router>
      </div>
    </>
  );
};
export default Header;

// fundamentals
// import React, { useEffect, useState } from "react";
// import {
//     BrowserRouter as Router,
//     Routes,
//     Route,
//     NavLink,
//     Link
//   } from "react-router-dom";

// // images
// import logo from "../Assets/Images/Logo/logo.png"

// // routes
// import { routes } from "../_Constants/routes";
// // import ScrollToTop from "./scrollToTop";
// import "../Css/global.scss";
// import "../Css/header.scss";
// import Home from "../Modules/Home/home";
// import Temple from "../Modules/Temple/temple";
// import Gabbar from "../Modules/Gabbar/gabbar";

// // extras
// import Modal from "react-modal";
// import { FaCartPlus, FaUserCircle, FaLock } from "react-icons/fa";

// const Header = () => {
//   const [isToggleActive, setIsToggleActive] = useState(false);
//   const [openModal, setOpenModal] = useState(false);

//     // for fix header
//     const [fix, setFix] = useState(false);
//     function setFixed() {
//       if (window.scrollY >= 130) {
//         setFix(true);
//       } else {
//         setFix(false);
//       }
//     }
//     // ---

//     // lastSegment
//   const url = window.location.href;
//   const lastSegment = url.split("/").pop();
//     // ---

//     const handleHomeLinkClick = () => {
//         setIsToggleActive(false); // Close the sidebar when Home link is clicked
//       };

//     return (
//         <>
//         <div className="wrapper">
//           <Router>
//             <Header
//               className={fix ? "header fixed" : "header"}
//               id="non-printable"
//             >
//               <div
//                 id="google_translate_element"
//                 className="d-block d-lg-block"
//               ></div>
//               <div className={lastSegment === "" ? "home" : ""}>
//                 <div className="container">
//                   <div className="header-wrapper">
//                     <div className="header-logo">
//                       <NavLink to="/">
//                         <img src={logo} alt="Ambaji Temple Banaskatha Gujarat"></img>
//                       </NavLink>
//                     </div>
//                     <div className="header-right">
//                       <nav className={isToggleActive ? "toggle_open" : ""}>
//                         <ul>
//                           <li>
//                             <NavLink
//                               to="/"
//                               activeclassname="active"
//                               onClick={handleHomeLinkClick}
//                             >
//                               હોમ
//                             </NavLink>
//                           </li>

//                           <li>
//                           <NavLink
//                             to={routes?.temple}
//                             activeclassname="active"
//                             onClick={handleHomeLinkClick}
//                           >
//                             મંદિર
//                           </NavLink>
//                         </li>
//                         <li>
//                           <NavLink
//                             to={routes?.gabbar}
//                             activeclassname="active"
//                             onClick={handleHomeLinkClick}
//                           >
//                             ગબ્બર
//                           </NavLink>
//                         </li>

//                         {/* <li>
//                           <NavLink
//                             to={routes?.shaktipithSang}
//                             activeclassname="active"
//                             onClick={handleHomeLinkClick}
//                           >
//                             <strong>પરિક્રમા સંઘ નોંધણી</strong>
//                           </NavLink>
//                         </li>

//                         <li>
//                           <Link
//                             to="/"
//                             className="dropdown-toggle"
//                             data-bs-toggle="dropdown"
//                             aria-expanded="false"
//                           >
//                             ઓનલાઇન સેવાઓ
//                           </Link>
//                           <ul className="mt-2 custom-dropdown-menu dropdown-menu dropdown-menu-bottom">
//                             <li>
//                               <Link
//                                 to={routes?.prashadList}
//                                 activeclassname="active"
//                                 onClick={handleHomeLinkClick}
//                               >
//                                 ઓનલાઈન પ્રસાદ
//                               </Link>
//                             </li>
//                             <li>
//                               <Link
//                                 to={routes?.productList}
//                                 activeclassname="active"
//                                 onClick={handleHomeLinkClick}
//                               >
//                                 ઓનલાઇન ખરીદી
//                               </Link>
//                             </li>
//                           </ul>
//                         </li>

//                         <li>
//                           <NavLink
//                             to={routes?.contactUs}
//                             activeclassname="active"
//                             onClick={handleHomeLinkClick}
//                           >
//                             અમારો સંપર્ક કરો
//                           </NavLink>
//                         </li>
//                         <li style={{ marginRight: "20px", marginTop: "2px" }}>
//                           <NavLink
//                             to={routes?.cart}
//                             activeclassname="active"
//                             onClick={handleHomeLinkClick}
//                             title="શોપિંગ કાર્ટ"
//                           >
//                             <FaCartPlus className="d-none d-lg-block" />
//                             <span className="d-lg-none">શોપિંગ કાર્ટ</span>
//                           </NavLink>
//                         </li>
//                         <li style={{ marginTop: "2px" }}>
//                           <NavLink
//                             to={routes?.userProfile}
//                             activeclassname="active"
//                             onClick={handleHomeLinkClick}
//                             title="પ્રોફાઇલ"
//                           >
//                             <FaUserCircle className="d-none d-lg-block" />
//                             <span className="d-lg-none">પ્રોફાઇલ</span>
//                           </NavLink>
//                         </li>
//                         {localStorage.getItem("token") && (
//                           <li className="d-block d-lg-none">
//                             <FaLock
//                               color="white"
//                               onClick={() => setOpenModal(true)}
//                             />
//                           </li>
//                         )}

// <div className="d-lg-block button-close dropdown">
//                       <button
//                         className="whitebtn dropdown-toggle"
//                         type="button"
//                         data-bs-toggle="dropdown"
//                         aria-expanded="false"
//                       >
//                         દાન
//                       </button>
//                       <ul className="mt-2 custom-dropdown-menu dropdown-menu">
//                         <li>
//                           <Link
//                             to={routes?.moneyDonation}
//                             className="dropdown-item"
//                           >
//                             પૈસા
//                           </Link>
//                         </li>
//                         <li>
//                           <Link
//                             to={routes?.goldDonation}
//                             className="dropdown-item"
//                           >
//                             સોનું
//                           </Link>
//                         </li>
//                       </ul>
//                     </div>
//                     <div className="d-none d-lg-block dropdown">
//                       <button
//                         className="whitebtn dropdown-toggle"
//                         type="button"
//                         data-bs-toggle="dropdown"
//                         aria-expanded="false"
//                       >
//                         દાન
//                       </button>
//                       <ul className="mt-2 custom-dropdown-menu dropdown-menu">
//                         <li>
//                           <Link
//                             to={routes?.moneyDonation}
//                             className="dropdown-item"
//                           >
//                             પૈસા
//                           </Link>
//                         </li>
//                         <li>
//                           <Link
//                             to={routes?.goldDonation}
//                             className="dropdown-item"
//                           >
//                             સોનું
//                           </Link>
//                         </li> */}
//                       {/* </ul>
//                     </div> */}

//                      </ul>
//                       </nav>

//                       <div
//                         className="toggle"
//                         onClick={() =>
//                           isToggleActive
//                             ? setIsToggleActive(false)
//                             : setIsToggleActive(true)
//                         }
//                       >
//                         <span
//                           className={
//                             isToggleActive
//                               ? "close-line-toggle line-toggle"
//                               : "line-toggle"
//                           }
//                         ></span>
//                         <span
//                           className={
//                             isToggleActive
//                               ? "close-line-toggle line-toggle"
//                               : "line-toggle"
//                           }
//                         ></span>
//                         <span
//                           className={
//                             isToggleActive
//                               ? "close-line-toggle line-toggle"
//                               : "line-toggle"
//                           }
//                         ></span>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </Header>

// {/* logout confirmation modal  */}
{
  /* <Modal
  className="logout-modal"
  isOpen={openModal}
  onRequestClose={() => setOpenModal(false)}
  //   style={customStyles}
  shouldCloseOnOverlayClick={false}
  contentLabel="Example Modal"
>
  <LogOutConfirmationModal closeModal={() => setOpenModal(false)} />
</Modal>; */
}
// {/* --- */}

//             {/* for go to the first section using this button  */}
//             {/* <ScrollToTop /> */}
//             {/* --- */}

//             {/* routes  */}
//             <Routes>
//               <Route path="" element={<Home />} />
//               <Route path={routes?.home} element={<Home />} />
//             <Route path={routes?.temple} element={<Temple />} />
//             <Route path={routes?.gabbar} element={<Gabbar />} />
//             </Routes>
//             {/* --- */}

//           </Router>
//         </div>
//       </>
//     )
// }

// export default Header;
