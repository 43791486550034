import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

const currentYear = new Date().getFullYear();

export function useHeading() {
  const location = useLocation();

  const getHeading = () => {
    switch (location.pathname) {
      case '/seva-terms-conditions':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>સેવા કેમ્પ માટેની શરતો</p>
          </>
        );
      case '/seva-camp':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>સેવા કેમ્પ</p>
          </>
        );
      case '/seva-camp-booking-receipt/:id':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <h2>સેવા કેમ્પ નોંધણી ની રસીદ</h2>
          </>
        );
      case '/sang-terms-conditions':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>સંઘ માટેની શરતો</p>
          </>
        );
      case '/sang':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>પદયાત્રા સંઘ નોંધણી</p>
          </>
        );
      case '/sangh-booking-receipt/:id':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>પદયાત્રા સંઘ નોંધણી ની રસીદ</p>
          </>
        );
      case '/bhadarvi-search':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>સેવા - કેમ્પ અને સંઘ નોંધણી સર્ચ</p>
          </>
        );
      case '/employee-vehicle-pass':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>વાહન પાસ નોંધણી </p>
          </>
        );
      case '/employee-vehicle-pass/registration/success/:id':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>કર્મચારી વાહન પાસ નોંધણી સફળતા</p>
          </>
        );
      case '/ambaji-sthanik-vehicle-pass':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>અંબાજી સ્થાનિક વાહન પાસ નોંધણી</p>
          </>
        );
      case '/ambaji-sthanik-vehicle-pass/registration/success/:id':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>અંબાજી સ્થાનિક વાહન પાસ નોંધણી સફળતા</p>
          </>
        );
      case '/search-ambaji-sthanik-vehicle-pass':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>સર્ચ અંબાજી સ્થાનિક વાહન પાસ</p>
          </>
        );
      case '/search-ambaji-sthanik-vehicle-pass/registration/success/:id':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>સર્ચ અંબાજી સ્થાનિક વાહન પાસ નોંધણી સફળતા </p>
          </>
        );
      case '/bhadarvi-poonam-receipt-download':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>સેવા - કેમ્પ અને સંઘ નોંધણી ની રસીદ</p>
          </>
        );
      default:
        return (
          <>
            <h1>Ambaji Bhadarvi Poonam {currentYear}</h1>
          </>
        );
    }
  };

  return getHeading();
}
