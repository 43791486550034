import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Footer from '../../Components/footer';
import LazyLoader from '../../Components/lazyLoader';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import './shaktiDarshanam.scss';
import imagesSubscribe from '../../Assets/Images/Footer/images-Subscribe.jpg';
import magazine from '../../Assets/Images/Footer/magazine.png';
import Arasuri from '../../Components/arasuri';
import Error404 from '../../Pages/error404';

const ShaktiDarshanam = () => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const [loading, setLoading] = useState(true);
  setTimeout(() => {
    setLoading(false);
  }, [3000]);

  const ShaktiDarshanamAddress = () => {
    navigate('/shakti-darshanam-address');
  };
  const AdvertisementBooking = () => {
    navigate('/advertisement-booking');
  };

  //-----page-close-404-error-logic-p-----
  if (true) {
    return <Error404 />;
  }
  //-----page-close-404-error-logic-p-----
  return (
    <>
      {!loading ? (
        <div className="main-wrapper">
          <section className="header-banner temple-banner">
            <div className="container">
              <h1>શક્તિ દર્શનમ્</h1>
            </div>
          </section>

          <div className="shakti-banner">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="date-section">
                    <p>
                      Filter By Date
                      <span style={{ marginRight: '10px' }}> </span>
                      <DatePicker
                        selected={startDate}
                        onChange={handleDateChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        placeholderText="Select Date Range"
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                  <div className="societybox">
                    <div className="societybox-img">
                      <img
                        src="https://storage.googleapis.com/ambaji-temple.appspot.com/1721198429475_ank-203.jpg"
                        alt="Ambaji Temple Banaskatha Gujarat"
                      />
                    </div>
                    <div className="societybox-body">
                      <h5 className="my-3">
                        203 - અંક - અંબાજી - શક્તિ દર્શનમ્
                      </h5>

                      <div className="btn-group mb-2">
                        <a
                          aria-current="page"
                          className="orangebtn active"
                          href="https://storage.googleapis.com/ambaji-temple.appspot.com/1721199814253_203-AnkAmbajiall.pdf"
                          target="_blank"
                        >
                          વધુ વાચો
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                  <div className="societybox">
                    <div className="societybox-img">
                      <img
                        src="https://storage.googleapis.com/ambaji-temple.appspot.com/1721198429470_ank-200.jpg"
                        alt="Ambaji Temple Banaskatha Gujarat"
                      />
                    </div>
                    <div className="societybox-body">
                      <h5 className="my-3">
                        200 - અંક - અંબાજી - શક્તિ દર્શનમ્
                      </h5>

                      <div className="btn-group mb-2">
                        <a
                          aria-current="page"
                          className="orangebtn active"
                          href="https://storage.googleapis.com/ambaji-temple.appspot.com/1715158583559_200-Ank-Ambaji-1to32-cm.pdf"
                          target="_blank"
                        >
                          વધુ વાચો
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                  <div className="societybox">
                    <div className="societybox-img">
                      <img
                        src="https://storage.googleapis.com/ambaji-temple.appspot.com/1721198429480_ank-199.jpg"
                        alt="Ambaji Temple Banaskatha Gujarat"
                      />
                    </div>
                    <div className="societybox-body">
                      <h5 className="my-3">
                        199 - અંક - અંબાજી - શક્તિ દર્શનમ્
                      </h5>

                      <div className="btn-group mb-2">
                        <a
                          aria-current="page"
                          className="orangebtn active"
                          href="https://storage.googleapis.com/ambaji-temple.appspot.com/1710754045884_199-Ank-Ambaji-ShakatiDarshanam-March-2024_CTOC.pdf"
                          target="_blank"
                        >
                          વધુ વાચો
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="Subscribe-section">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div className="Subscribe">
                      <h3>
                        <span>પેપર કોપી પર </span> સબ્સ્ક્રાઇબ કરો
                      </h3>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.{' '}
                      </p>
                      <p>
                        Lorem Ipsum has been the industry's standard dummy text
                        ever since the 1500s, when an unknown printer took a
                        galley of type and scrambled it to make a type specimen
                        book.
                      </p>

                      <div className="orange">
                        <div className="btn-group mb-2">
                          <a
                            className="orangebtn active"
                            target="_blank"
                            onClick={() => ShaktiDarshanamAddress()}
                          >
                            સબ્સ્ક્રાઇબ કરો
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div className="Subscribe-img">
                      <img
                        src={imagesSubscribe}
                        alt="Ambaji Temple Banaskatha Gujarat"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="Advertise-business">
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="Business-text">
                      <h3>
                        <span>મંદિરના ભક્તોને</span> તમારા વ્યવસાયની જાહેરાત કરો
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-4 col-md-6 col-sm-6 col-12">
                    <div className="pack-price-cart ">
                      <h4>ટાઈટલ</h4>
                      <div className="pack-price-cart-whitebg">
                        <h5>પાના નંબર - ૪ </h5>
                        <div className="pack-price-cart-gryBg">
                          <div className="pricebd">
                            <span className="rssing">₹</span>
                            <span className="sp-price">20,000</span>
                            <span className="inyear">/ Edition</span>
                          </div>
                        </div>
                        <div className="magzine-img">
                          <img
                            src={magazine}
                            alt="Ambaji Temple Banaskatha Gujarat"
                          />
                        </div>
                        <div className="linkbtn">
                          <div className="btn-group">
                            <a
                              className="orangebtn"
                              target="_blank"
                              onClick={() => AdvertisementBooking()}
                            >
                              હમણાં બુક કરો
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-md-6 col-sm-6 col-12">
                    <div className="pack-price-cart ">
                      <h4>ટાઈટલ</h4>
                      <div className="pack-price-cart-whitebg">
                        <h5>પાના નંબર - ૨ અને ૩ </h5>
                        <div className="pack-price-cart-gryBg">
                          <div className="pricebd">
                            <span className="rssing">₹</span>
                            <span className="sp-price">11,000</span>
                            <span className="inyear">/ Edition</span>
                          </div>
                        </div>
                        <div className="magzine-img">
                          <img
                            src={magazine}
                            alt="Ambaji Temple Banaskatha Gujarat"
                          />
                        </div>
                        <div className="linkbtn">
                          <div className="btn-group">
                            <a
                              className="orangebtn"
                              target="_blank"
                              onClick={() => AdvertisementBooking()}
                            >
                              હમણાં બુક કરો
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-md-6 col-sm-6 col-12">
                    <div className="pack-price-cart ">
                      <h4>અંદરના</h4>
                      <div className="pack-price-cart-whitebg">
                        <h5>અંક પાનાના </h5>
                        <div className="pack-price-cart-gryBg">
                          <div className="pricebd">
                            <span className="rssing">₹</span>
                            <span className="sp-price">5,000</span>
                            <span className="inyear">/ Edition</span>
                          </div>
                        </div>
                        <div className="magzine-img">
                          <img
                            src={magazine}
                            alt="Ambaji Temple Banaskatha Gujarat"
                          />
                        </div>
                        <div className="linkbtn">
                          <div className="btn-group">
                            <a
                              className="orangebtn"
                              target="_blank"
                              onClick={() => AdvertisementBooking()}
                            >
                              હમણાં બુક કરો
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>

                  <div className="col-xl-4 col-md-6 col-sm-6 col-12">
                    <div className="pack-price-cart ">
                      <h4>અડધા</h4>
                      <div className="pack-price-cart-whitebg">
                        <h5>પાનાના</h5>
                        <div className="pack-price-cart-gryBg">
                          <div className="pricebd">
                            <span className="rssing">₹</span>
                            <span className="sp-price">3,000</span>
                            <span className="inyear">/ Edition</span>
                          </div>
                        </div>
                        <div className="magzine-img">
                          <img
                            src={magazine}
                            alt="Ambaji Temple Banaskatha Gujarat"
                          />
                        </div>
                        <div className="linkbtn">
                          <div className="btn-group">
                            <a
                              className="orangebtn"
                              target="_blank"
                              onClick={() => AdvertisementBooking()}
                            >
                              હમણાં બુક કરો
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>

                  <div className="col-xl-4 col-md-6 col-sm-6 col-12">
                    <div className="pack-price-cart ">
                      <h4>૧/૪</h4>
                      <div className="pack-price-cart-whitebg">
                        <h5>પાનાના </h5>
                        <div className="pack-price-cart-gryBg">
                          <div className="pricebd">
                            <span className="rssing">₹</span>
                            <span className="sp-price">1,500</span>
                            <span className="inyear">/ Edition</span>
                          </div>
                        </div>
                        <div className="magzine-img">
                          <img
                            src={magazine}
                            alt="Ambaji Temple Banaskatha Gujarat"
                          />
                        </div>
                        <div className="linkbtn">
                          <div className="btn-group">
                            <a
                              className="orangebtn"
                              target="_blank"
                              onClick={() => AdvertisementBooking()}
                            >
                              હમણાં બુક કરો
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Arasuri />
          <Footer />
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
};

export default ShaktiDarshanam;
