import "./App.css";
import "./Modules/Home/home.scss";
import Header from "./Components/header";
import { HelmetProvider } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import WhatsAppLogo from "./Assets/Images/Social media/WhatsAppLogo.png";
import React, { useEffect } from "react";

function App() {
  useEffect(() => {
    window.oncontextmenu = function (e) {
      e.preventDefault();
    };

    const handleKeyDown = (event) => {
      if (event.keyCode === 123) {
        // F12
        event.preventDefault();
      } else if (
        (event.ctrlKey && event.shiftKey && event.keyCode === 73) || // Ctrl+Shift+I
        (event.ctrlKey && event.shiftKey && event.keyCode === 74) || // Ctrl+Shift+J
        (event.ctrlKey && event.keyCode === 85) // Ctrl+U
      ) {
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      window.oncontextmenu = null;
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const helmetContext = {};

  return (
    <HelmetProvider context={helmetContext}>
      <div className="app">
        <div className="whatsapp-icon-1" id="non-printable">
          <a
            href="https://api.whatsapp.com/send?phone=+918799305151&amp;text=Jay Ambe...!"
            target="_blank"
          >
            <img
              src={WhatsAppLogo}
              alt=""
              style={{ backgroundColor: "#fff", borderRadius: "50%" }}
            />
          </a>
        </div>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <Header />
      </div>
    </HelmetProvider>
  );
}

export default App;
