export const api_urls = {
  // UTILS
  getCountry: "/master/country/get",
  getProvince: "/master/province/get",
  getCity: "/master/city/get",
  getZipcode: "/master/zipcode/get",
  getZipcodeByCity: "/master/zipcode/get",
  getZipcodeByCityDeliverable: "/master/zipcode/get",
  getZipcodeByKeyword: "/master/zipcode/get",
  getTithi: "/master/tithi/get",

  // SIGNATURE
  getSignatureAll: "master/signature-profile/get",

  /** CMS */
  getAll: "/cms/home-slider/get",

  /** Event */
  getEvent: "/cms/event/get",

  /** Blog */
  getBlog: "blog/get",

  /** news */
  getAllNews: "/blog/news/get",
  getByNewsId: "/blog/news/get",

  /** Product */
  getProduct: "product/get",

  /** Schedule */
  getSchedule: "/cms/schedule/get",

  /** tourist attraction  */
  getTouristAttraction: "/cms/tourist-attraction/get",

  /** inquiry  */
  createInquiry: "/inquiry/save",
  getAllInquiryType: "inquiry/type/get",
  getAllInquiryTypeQue: "inquiry/master/rating/type/get",

  /** 51 shakipith  */
  getShakipith: "/cms/51-shakti-pith/get",
  getByShaktipithKeyword: "/gabbar-shakti-pith-sang/get",

  /** Gabbar shaktipith sang  */
  createShaktipithSang: "/gabbar-shakti-pith-sang/save",
  shaktipithSang_sendOTP: "/user/sms/send/otp",
  shaktipithSang_verifyOTP: "/user/sms/verify/otp",

  /** Yagna Booking */
  getYagnaCategory: "/yagna/category/get",
  getYagnaTemple: "/yagna/temple/get",
  getYagna: "yagna/get",
  yagnaBook: "/yagna/book",
  //   yagna_checkForAvailability: "yagna/slot/availability-check",
  //   yagna_getAllAvailableDates: "yagna/slot/get/list-of-available-dates",
  getTempleByDate: "/yagna/slot/get/list-of-available-temple",
  getOfflineBoking: "/yagna/book-via-ambaji-temple",
  //for otp
  yagna_sendOTP: "/yagna/send/otp-sms",
  yagna_verifyOTP: "/yagna/verify/otp",

  // AUTH

  // ---------LOGIN---------
  login: "user/login",

  // ---------SIGN UP---------
  signUp: "user/create",
  getBySignUpKeyword: "user/get",

  // ---------FORGOT PASSWORD---------
  forgotPassword: "user/password/reset",

  // USER PROFILE
  createUser: "user/create",
  getUser: "user/get",
  updateUser: "user/update",

  // ---------ADDRESS DETAILS------------
  createAddress: "user/address/save",
  getAddress: "user/address/get",
  deleteAddress: "user/address/delete",

  // ---------ORDERS------------
  getOrder: "order/get",
  getCounterOrder: "counter/order/get",
  // USER OTP

  // ---------SEND OTP---------
  sendOtp: "user/sms/send/otp",

  // ---------VERIFY OTP---------
  verifyOtp: "user/sms/verify/otp",

  //   DONATIONS

  // ---------MONEY DONATIONS---------
  getMoneyDonation: "donation/money/get",
  getGoldCounter: "donation/counter/gold/get",
  getMoneyCounter: "donation/counter/money/get",
  createMoneyDonation: "donation/money/payu/payment",
  moneyDonation_sendOTP: "donation/send/sms/otp",
  moneyDonation_verifyOTP: "donation/verify/sms/otp",
  getAllCause: "donation/money/type/get",

  // ---------GOLD DONATIONS---------
  getGoldDonation: "donation/gold/get",
  getArticalDonation: "donation/counter/article/get",
  createGoldDonation: "donation/gold/razorpay/payment",
  goldDonation_sendOTP: "donation/send/sms/otp",
  goldDonation_verifyOTP: "donation/verify/sms/otp",

  // E-STORE

  getCounterPrasad: "counter/order/get",
  // ---------ONLINE PRASAD---------
  getPrasadAll: "product/get",

  // ---------ONLINE PRASAD CART---------
  buyNow: "order/create",
  createPrasadCart: "order/prasad/cart/save",

  // ---------ONLINE SHOP---------
  getShopAll: "product/get",

  // ---------ONLINE SHOPPING CART---------
  getCartData: "/order/e-store/cart/get",
  // GetByIdCartData: "/order/e-store/cart/get",
  createShoppingCart: "order/e-store/cart/save",
  removeCartData: "order/e-store/cart/delete",
  // removeCartData: "order/e-store/cart/save",

  // ---------ONLINE PRASAD CART---------
  getBuyNowData: "/order/prasad/cart/save",
  getPrasadcart: "order/prasad/cart/get",

  // ---------CHECKOUT---------
  checkout: "order/create",
  calculateDeliveryCharge: "order/calculate/delivery-charges",

  // BHADARVI POONAM

  // ---------SEVA CAMP---------
  createSevaCamp: "bhadarvi-poonam/seva-camp/save",
  getSevaCampCategory: "bhadarvi-poonam/seva-camp/category/get",
  getAllSevaCamp: "bhadarvi-poonam/seva-camp/get",
  getAllVehicleCategory: "bhadarvi-poonam/vehicle-category/get",
  getBySevaCampKeyword: "bhadarvi-poonam/seva-camp/get",
  getTermsConditions: "bhadarvi-poonam/terms-and-condition/get",

  // ---------SANG---------
  createSangh: "bhadarvi-poonam/sangh/save",
  getArrivalroute: "bhadarvi-poonam/arrival-route/get",
  getAllSangh: "bhadarvi-poonam/sangh/get",
  getBySanghKeyword: "bhadarvi-poonam/sangh/get",

  // ----------------Employee Vehicle Pass-----------

  createEmployeepass: "bhadarvi-poonam/employee-vehicle-pass/save",
  getAllEmployeeVehiclePass: "bhadarvi-poonam/employee-vehicle-pass/get",

  // ---------------------Dhaja Booking----------------

  getSlots: "dhaja/generate/time/slot",
  getDhaja: "dhaja/get",
  getDhajaBooking: "dhaja/booking/get",
  createDhajaBooking: "dhaja/book-via-website",

  // --------------Swayam Sevak------------

  createSwayamSevak: "swayam-seva/seva/save",
  getAllSeva: "swayam-seva/type/get",
  saveInFirebase: "cms/assets/save",
  getSwayamSevak: "swayam-seva/seva/get",
};

// payment integration api
export const payu_api_url = "https://secure.payu.in/_payment?";
export const payu_key = "X2BQDt";
